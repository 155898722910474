// import Container from 'react-bootstrap/Container';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React from 'react';
import Common from "../../layouts/Common";
// import Container from 'react-bootstrap/Container';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { FaArrowRight, FaCheckCircle, FaClock, FaCommentAlt, FaCommentDots, FaFileAlt, FaFileCode, FaFileInvoice, FaGlobe, FaLaptop, FaLockOpen, FaMobileAlt, FaScrewdriver, FaSearchengin, FaServer, FaSolarPanel, FaSoundcloud, FaUserAlt, FaWaveSquare } from 'react-icons/fa';
import indcom from '../../assets/images/indcom.svg';
import serviceall from '../../assets/images/serviceall.svg';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ContactForm from '../../components/ContactForm';
import IndustryExperience from '../../components/IndustryExperience';
import RecentWork from '../../components/RecentWork';



const Aspnet = () => {

  const iconList = [FaArrowRight, FaCheckCircle, FaClock, FaCommentAlt, FaCommentDots, FaFileAlt, FaFileCode, FaFileInvoice, FaGlobe, FaLaptop, FaLockOpen, FaMobileAlt, FaScrewdriver, FaSearchengin, FaServer, FaSolarPanel, FaSoundcloud, FaUserAlt, FaWaveSquare];
  const iconList2 = ['FaArrowRight', 'FaCheckCircle', 'FaClock', 'FaCommentAlt', 'FaCommentDots', 'FaFileAlt', 'FaFileCode', 'FaFileInvoice', 'FaGlobe', 'FaLaptop', 'FaLockOpen', 'FaMobileAlt', 'FaScrewdriver', 'FaSearchengin', 'FaServer', 'FaSolarPanel', 'FaSoundcloud', 'FaUserAlt', 'FaWaveSquare'];

  const serviceData =
  {
    "title": "ASP.Net Development Company in Bangladesh",
    "subTitle": "Entrust your requirement to build a feature-packed and mobile-friendly web application with Nexalinx, one of the best ASP.Net development companies in Bangladesh. Nexalinx Solutions developers are experts in Microsoft .NET development, bringing the benefits of ASP.NET’s flexibility, reliability, compatibility and scalability to clients in many industries. In the growing space of competition, we thrive in creating extremely easy ASP.NET projects.",
    "feature": [
      {
        "title": "SECURITY & IP PROTECTION",
        "icon": "FaLockOpen"
      },
      {
        "title": "1 YEARS EXPERIENCE",
        "icon": "FaGlobe"
      },
      {
        "title": "FLEXIBLE ENGAGEMENT MODELS",
        "icon": "FaWaveSquare"
      },
      {
        "title": "STRICT NDA TERMS",
        "icon": "FaFileAlt"
      },
      {
        "title": "NO HIDDEN COSTS",
        "icon": "FaFileInvoice"
      },
      {
        "title": "ON-TIME PROJECT DELIVERY",
        "icon": "FaClock"
      }
    ],
    "heading_one": {
      "title": "Get Ready For A Today’s And Tomorrow’s ASP.Net Development With Nexalinx Solutions",
      "details": "Nexalinx Solutions developers are experts in Microsoft .NET development, bringing the benefits of ASP.NET’s flexibility, reliability, compatibility and scalability to clients in many industries. In the growing space of competition, we thrive in creating extremely easy ASP.NET projects. ASP.NET is the initial requirement for new web applications with a modern UI and solid architecture. Microsoft created ASP.NET for a faster and more reliable web application development that is best suited for future internet needs.",
      "image": "https://www.image.com"
    },
    "heading_two": {
      "title": "Advantages of outsourcing",
      "details": "",
      "feature_point": [
        {
          "title": "",
          "icon": "FaCheckCircle"
        }
      ],
      "image": "https://www.image.com"
    },
    "service_items": {
      "title": "Our ASP.NET Development Services",
      "subTitle": "Nexalinx, being one of the leading ASP NET development companies in Bangladesh, offers the best available development services for varied industries. Our ASP.NET development services in Bangladesh are unique to satisfy our clients’ requirements even in a very competitive market.",
      "image": "https://www.image.com",
      "items": [
        {
          "title": "ASP.NET Web App Development",
          "details": "Nexalinx is a top ASP.NET development company in Bangladesh that has a team of skilled developers who build robust and high-performance web app solutions. Since ASP.NET consists of a vast number of different controls like buttons, labels, text boxes etc. we are able to develop interactive and data-driven web applications efficiently. We provide top-notch ASP.NET development services in Bangladesh to create outstanding enterprise-grade web applications for various domains. Utilize our expertise to craft highly scalable and secure ASP.NET web applications that can satisfy your requirements with the best quality."
        },
        {
          "title": "ASP.NET API Development",
          "details": "Our proficient ASP.NET developers have great technical knowledge in building RESTful applications on the .NET framework. By leveraging the ASP.NET Web API framework, we develop secure APIs that are truly dependable and easy to consume. We have a competent team of ASP.NET developers who can create custom APIs in order to equip your various business models and ensure reliable integration with a variety of apps. Being one of the leading ASP.NET development companies in Bangladesh, our developers are capable of comprehending even the most complex requirements of our clients and wisely using an API development process."
        },
        {
          "title": "ASP.NET CMS Development",
          "details": "Our skilled developers provide the best CMS development solutions that offer an intuitive, easy-to-use, and convenient interface for users. We utilize advanced tools and techniques and follow best practices to build CMS websites compliant with international web standards. We deliver secure and scalable content management systems apps for a variety of business verticals to automate all the content processes. As the best provider of ASP.NET development services in Bangladesh, we create custom CMS based websites that fulfill varying requirements with finesse."
        },
        {
          "title": "ASP.NET Consultation",
          "details": "Our consultants will assist you in getting ready for the next generation line of business applications, which will allow for more flexible business processes. Nexalinx is one of the eminent ASP.NET development companies in Bangladesh that provide result-oriented technical guidance to businesses. We can resolve complex software development issues and help you save time and cost on a software development project. We can guarantee that you focus entirely on your organizational objectives while we remove the IT barriers that are limiting you from expanding."
        },
        {
          "title": "ASP.NET Migration And Upgradation",
          "details": "Our expert developers are efficient in migrating your legacy application to the ASP.NET platform according to the latest design and practices. We can provide migration services to add new functionalities to your current application and assist you in keeping up with technological advancements. We are talented to upgrade your existing application to the latest version of ASP.NET in order to meet evolving business demands. We will offer high-end migration and upgrade services to patch up your outdated system without impacting data security."
        },
        {
          "title": "Support & Maintenance For ASP.NET Applications",
          "details": "As a best-in-class ASP.NET development company in Bangladesh, we will provide comprehensive support and maintenance services to update your applications so that you can offer the latest UI and error-free UX to your customers. Our professional ASP.NET support team can assist you with resolving issues and bugs in your apps while also boosting the overall performance of your system. We can offer high-performance business applications with exceptional service available in order to maximize your business growth. We will thoroughly examine your system and implement standardized practices to ensure seamless application performance."
        }
      ]
    }
  };

  const renderFeature = (data, index) => {
    const Icon = iconList2.findIndex(element => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (

      <Col lg={6} sm={12} key={index}>
        <div className="technology-list"> <span>
          <Icon2 />
        </span>
          <label> {data.title} </label>
        </div>
      </Col>
    );
  };

  const renderlist = (data, index) => {
    const Icon = iconList2.findIndex(element => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (

      <li key={index}> <span>
        <Icon2 />
      </span>
        <p>{data.title}</p>
      </li>
    );
  };

  const renderServiceLeft = (data, index) => {
    const Icon = iconList2.findIndex(element => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (

      <div className="company-slide mission-vission" key={index}>
        <Row>
          <Col md={3}>
            <div className="slider-icon icon-all" data-aos="flip-left">
              <Icon2 />
            </div>
          </Col>
          <Col md={9} className="ps-0">
            <h5 className='p-0'>{data.title}</h5>
            <p className='mb-0'> {data.details} </p>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white"><strong>{serviceData.title}</strong></h1>
                <p className="text-white p20 mb-4">{serviceData.subTitle}</p>
              </div>
              <Row>
                {serviceData.feature.map(data => renderFeature(data))}
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section>
      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <img className="width-full mt-5" src={indcom} alt="indcom" />
            </Col>
            <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
              <h2>{serviceData?.heading_one?.title}</h2>
              <p>{serviceData?.heading_one?.details}</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2>{serviceData.service_items.title}</h2>
              <p> {serviceData.service_items.subTitle} </p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaLaptop />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className='p-0'>{serviceData.service_items?.items[0].title}</h5>
                    <p className='mb-0'> {serviceData.service_items?.items[0].details} </p>
                  </Col>
                </Row>
              </div>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className='p-0'>{serviceData.service_items?.items[1].title}</h5>
                    <p className='mb-0'> {serviceData.service_items?.items[1].details} </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaMobileAlt />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className='img-width' src={serviceall} alt="serviceall" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaServer />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className='p-0'>{serviceData.service_items?.items[2].title}</h5>
                    <p className='mb-0'> {serviceData.service_items?.items[2].details} </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className='p-0'>{serviceData.service_items?.items[3].title}</h5>
                    <p className='mb-0'> {serviceData.service_items?.items[3].details} </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaFileCode />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaSearchengin />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className='p-0'>{serviceData.service_items?.items[4].title}</h5>
                    <p className='mb-0'> {serviceData.service_items?.items[4].details} </p>
                  </Col>
                </Row>
              </div>
            </Col>

            {serviceData.service_items?.items[5] &&
              <Col md={6}>
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className='p-0'>{serviceData.service_items?.items[5]?.title}</h5>
                      <p className='mb-0'> {serviceData.service_items?.items[5]?.details} </p>
                    </Col>
                    <Col md={3}>
                      <div className="slider-icon icon-all" data-aos="flip-right">
                        <FaSolarPanel />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            }
            {serviceData.service_items?.items[6] &&
              <Col md={6} >
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className='p-0'>{serviceData.service_items?.items[6]?.title}</h5>
                      <p className='mb-0'> {serviceData.service_items?.items[6]?.details} </p>
                    </Col>
                    <Col md={3}>
                      <div className="slider-icon icon-all" data-aos="flip-right">
                        <FaScrewdriver />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            }
            {serviceData.service_items?.items[7] &&
              <Col md={6}>
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className='p-0'>{serviceData.service_items?.items[7]?.title}</h5>
                      <p className='mb-0'> {serviceData.service_items?.items[7]?.details} </p>
                    </Col>
                    <Col md={3}>
                      <div className="slider-icon icon-all" data-aos="flip-right">
                        <FaSoundcloud />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            }

          </Row>
        </Container>
      </section>
      <RecentWork />
      <IndustryExperience />
    </Common>
  )
};

export default Aspnet;