import React from 'react';
import workd from '../../assets/images/workd.png';
import angular from '../../assets/images/ani.svg';
import tech from '../../assets/images/tech.svg';
import mong from '../../assets/images/mong.svg';
import worka from '../../assets/images/worka.png';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Button from 'react-bootstrap/Button';
import { NavLink } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';


const Mobileapp = () => {
    return (
        <div>

       
        <Row className="mt-5 pt-5">

        <Col md={7} className="mt-3">
        
                
                <h2 className="mt-5">Differences Between MongoDB & MySQL</h2>
        
                <p className="pe-5"> 
                When it comes to database management systems, MySQL and MongoDB are certainly the most popular among developers.
        Relational systems such as MySQL use tables (containing rows and columns) to store data and make use of structured query languages (such as SQL) for data access. An SQL query generated...
                     </p>
                     <h4 className="mt-4 text-black mb-3"><strong>Technologies Used</strong></h4>
        
                        <div className="blog-tech">
                        <span><img src={angular} alt="angular" /></span>
                    <p>Angularjs</p>
                        </div>
                        <div className="blog-tech">
                        <span><img src={tech} alt="tech" /></span>
                    <p>ASP.NET MVC</p>
                        </div>
                        <div className="blog-tech">
                        <span><img src={tech} alt="tech" /></span>
                    <p>ASP.NET MVC</p>
                        </div>
                        <div className="blog-tech">
                        <span><img src={mong} alt="mong" /></span>
                    <p>Magento</p>
                    </div>
                        <div className="clearfix"></div>
                     <Col md={12} className="text-start mt-4">
                     <NavLink exact to="/blog-details"><Button variant="primary btn-custom mt-3 btn-big w260 w190">View Project
                <FaArrowRight className="ms-5" />
                </Button></NavLink>
                </Col>
                     </Col>
        
                     <Col md={5} className="mt-4 mb-4 blog-all">
                     <div className="company-slide">
                        <div className="slider-icon2"><img src={workd}  className="portimg" alt="workd" /> </div>
                      </div>
                     </Col>
              </Row>
              <Row className="mt-5 pt-5">
              <Col md={5} className="mt-4 mb-4 blog-all">
                     <div className="company-slide">
                     <div className="slider-icon2"><img src={worka} className="portimg" alt="worka" /> </div>
                      </div>
                     </Col>
        <Col md={7} className="mt-3 ps-5">
        
                
                <h2 className="mt-5">Vacation & Schedule Bidding System</h2>
        
                <p className="pe-5"> 
                This vacation & schedule bidding platform for the pilots and flight attendants was developed for a mutinational Airline company operating out of the east cost,he platform is available in Desktop, iPad, iPhone and MAC using line bidding system. Currently more than 20,000+ users are using this application for flight schedule bidding.
                     </p>
                     <h4 className="mt-4 text-black mb-3"><strong>Technologies Used</strong></h4>
        
                        <div className="blog-tech">
                        <span><img src={angular} alt="angular" /></span>
                    <p>Angularjs</p>
                        </div>
                        <div className="blog-tech">
                        <span><img src={tech} alt="tech" /></span>
                    <p>ASP.NET MVC</p>
                        </div>
                        <div className="blog-tech">
                        <span><img src={tech} alt="tech" /></span>
                    <p>ASP.NET MVC</p>
                        </div>
                        <div className="blog-tech">
                        <span><img src={mong} alt="mong" /></span>
                    <p>Magento</p>
                    </div>
                        <div className="clearfix"></div>
                     <Col md={12} className="text-start mt-4">
                     <NavLink exact to="/blog-details"><Button variant="primary btn-custom mt-3 btn-big w260 w190">View Project
                <FaArrowRight className="ms-5" />
                </Button></NavLink>
                </Col>
                     </Col>
        
                    
              </Row>
              </div>
    );
};

export default Mobileapp;