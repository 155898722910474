import React from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/Container";
import Common from "../layouts/Common";
import lethello from "../assets/images/lethello.svg";
import contactus from "../assets/images/contactus.svg";
import usa from "../assets/images/usa.webp";
import { FaPeriscope } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import bd from "../assets/images/bd.svg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FaCommentAlt } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { FaCommentDots } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import ContactTouch from "../components/ContactTouch";

const Contact = () => {
  return (
    <Common>
      <section className="all-header"></section>

      <section className="all-company-feature bg-deep-gray bg-deep-gray2 mt-3 pt-3 bg-white certification-slide blog-wr">
        <Container>
          <Row>
            <Col md={12} className="mt-4 mb-4">
              <section className="all-company-feature pt-2">
                <Container>
                  <Row>
                    <Col md={6} className="mb-4" data-aos="fade-right">
                      <div className="block-hello">
                        <h2 className="mt-3">Let's Talk Business!</h2>
                        <p>
                          <strong>
                            Have questions about your idea? Drop in your details
                            to discuss with our solution consultants. We look
                            forward to hear from you.
                          </strong>
                        </p>
                      </div>
                    </Col>
                    <Col md={6} className="mt-5 mb-4" data-aos="fade-left">
                      <img
                        className="abouimg width-100 mt-4"
                        src={lethello}
                        alt="lethello"
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            </Col>
          </Row>
        </Container>

        <div className="contact-bg bg-gray bg-deep-gray">
          <Container>
            <Col md={12} className="text-start ps-3"></Col>
            <Row>
              <Col md={6} className="p-0 left-contact" data-aos="fade-right">
                <iframe
                  className="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.5967063187745!2d-73.94101808430071!3d40.74889884329367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258d5803dbaf9%3A0xaadf2f1b2fd2797c!2s28-07%20Jackson%20Ave%2C%20Long%20Island%20City%2C%20NY%2011101%2C%20USA!5e0!3m2!1sen!2sbd!4v1681023016834!5m2!1sen!2sbd"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </Col>
              <Col
                md={6}
                className="mb-4 p-5 right-contact"
                data-aos="fade-right"
              >
                <h2 className="mt-0 mb-4">Our Headquarter</h2>
                <div className="head-ofc-area">
                  <img src={usa} alt="usa" className="allflag" />

                  <ul className="list-group list-inline company-list-link">
                    <h4>United States</h4>
                    <li className="mt-0">
                      {" "}
                      <span>
                        <FaPeriscope />
                      </span>
                      <label className="text-black">
                        {" "}
                        28-07 Jackson Ave, Long Island City, NY, 11101, USA{" "}
                      </label>
                    </li>
                    <li className="mt-0">
                      <span>
                        <FaMobileAlt />
                      </span>
                      <label className="text-black"> (332) 203-6066</label>
                    </li>
                  </ul>
                </div>

                <div className="head-ofc-area mt-2">
                  <img src={bd} alt="bd" className="allflag" />

                  <ul className="list-group list-inline company-list-link">
                    <h4>Bangladesh</h4>
                    <li className="mt-0">
                      {" "}
                      <span>
                        <FaPeriscope />
                      </span>
                      <label className="text-black">
                        CASABELLA house no -17 , road no- 128, gulshan-1,
                        Dhaka-1212{" "}
                      </label>
                    </li>
                    <li className="mt-0">
                      <span>
                        <FaMobileAlt />
                      </span>
                      <label className="text-black"> +880 1791001818</label>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          {/* <Row>
                <Col md={6} className="mb-4 p-5" data-aos="fade-right">
                    <div className='address'>
                    <img src={usa} alt="usa"  className='allflag' />
                    <h3>United States</h3>
                    <ul className='list-group list-inline company-list-link'>
        <li> <span>
          <FaPeriscope/>
          </span>
          <label className='text-black'> 28-07 Jackson Ave, Long Island City, NY, 11101, USA </label>
        </li>
        <li> <span>
          <FaMobileAlt/>
          </span>
          <label className='text-black'> (332) 203-6066</label>
        </li>
      </ul>
                    </div>
                </Col>
                <Col md={6} className="mb-4 p-5" data-aos="fade-right">
                    <div className='address'>
                    <img src={bd} alt="usa"  className='allflag' />
                    <h3>Bangladesh</h3>
                    <ul className='list-group list-inline company-list-link'>
        <li> <span>
          <FaPeriscope/>
          </span>
          <label className='text-black'> CASABELLA house no -17 , road no- 128, gulshan-1, Dhaka-1212 </label>
        </li>
        <li> <span>
          <FaMobileAlt/>
          </span>
          <label className='text-black'> +880 1791001818</label>
        </li>
      </ul>
                    </div>
                </Col>
                </Row> */}

          <Row className="border-form mt-5 pt-5" id="formall">
            <Col md={6} className="p-4 bg-form rounded-5" data-aos="fade-right">
              <ContactTouch/>
            </Col>

            <Col md={6} className="bg-white " data-aos="fade-right">
              <img
                className="width96 mt-2 mb-4"
                src={contactus}
                alt="contactus"
              />
              <h3 className="ps-5 ms-5">
                We'd Love To
                <br />
                Hear From You
              </h3>
              <p className="ps-5 ms-5">
                Have questions about your idea? Drop in your details to discuss
                with our solution consultants. We look forward to hear from you.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </Common>
  );
};

export default Contact;
