import React from 'react';

import Common from "../../layouts/Common";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { FaLockOpen } from 'react-icons/fa';
import { FaGlobe } from 'react-icons/fa';
import { FaWaveSquare } from 'react-icons/fa';
import { FaFileAlt } from 'react-icons/fa';
import { FaFileInvoice } from 'react-icons/fa';
import { FaClock } from 'react-icons/fa';
import { FaSlideshare } from 'react-icons/fa';
import { FaMobileAlt } from 'react-icons/fa';
import { FaUserAlt } from 'react-icons/fa';
import { FaCommentAlt } from 'react-icons/fa';
import { FaCommentDots } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FaArrowRight } from 'react-icons/fa';
import { FaChartBar } from 'react-icons/fa';

import { FaStarOfLife } from 'react-icons/fa';
import { FaScribd } from 'react-icons/fa';
import { FaFileCode } from 'react-icons/fa';
import { FaSearchengin } from 'react-icons/fa';
import { FaSolarPanel } from 'react-icons/fa';
import { FaMoneyCheckAlt } from 'react-icons/fa';
import { FaSuperpowers } from 'react-icons/fa';
import { FaChalkboardTeacher } from 'react-icons/fa';

import Container from 'react-bootstrap/Container';
import worka from '../../assets/images/worka.png';
import workb from '../../assets/images/workb.png';
import workc from '../../assets/images/workc.png';
import workd from '../../assets/images/workd.png';
import worke from '../../assets/images/worke.png';
import workf from '../../assets/images/workf.png';
import indu1 from '../../assets/images/indu1.svg';
import indu2 from '../../assets/images/indu2.svg';
import indu3 from '../../assets/images/indu3.svg';
import indu4 from '../../assets/images/indu4.svg';
import indu5 from '../../assets/images/indu5.svg';
import indu6 from '../../assets/images/indu6.svg';



import pwabenefit1 from '../../assets/images/pwa-benefit-1.svg';
import pwabenefit2 from '../../assets/images/pwa-benefit-2.svg';
import pwabenefit3 from '../../assets/images/pwa-benefit-3.svg';
import pwabenefit4 from '../../assets/images/pwa-benefit-4.svg';
import pwabenefit5 from '../../assets/images/pwa-benefit-5.svg';
import pwabenefit6 from '../../assets/images/pwa-benefit-6.svg';

import hirepwa from '../../assets/images/hire-pwa-develoers.svg';
import erpservice from '../../assets/images/erp-service-pic-1.png';


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ContactForm from '../../components/ContactForm';


const ErpSoftwareDevelopment = () => {
    const options2 = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        autoplay: true,
        // navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items:1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
            },
            1000: {
                items:3,
            }
        },
      };
    return (
        <Common>
  <section className="all-sub-banner">
            <div className="sub-banner">
      <Row className="m-0">
                <Col lg={8} sm={12}>
                <div className="slider-banner slide-arrow">
          <h1 className="text-white"><strong>
ERP Software Development
Services in Bangladesh
            </strong></h1>
          <p className="text-white p20 mb-4"> Nexalinx consists of experienced developers who are highly skilled in ERP software development and have built ERP software for a variety of businesses. We can develop powerful and high-performance ERP software that can optimize your internal business processes and help you achieve goals. We provide high-quality services and help businesses make the right decisions to increase efficiency and profitability. We can understand your business needs and assist you to improve system transparency, eliminate redundancy, and assure fewer errors throughout the organisation.
        
           </p>
        </div>
                <Row>
          <Col lg={6} sm={12}>
          <div className="technology-list"> <span>
            <FaLockOpen/>
            </span>
                    <label> Security & IP Protection </label>
                  </div>
          </Col>
          <Col lg={6} sm={12}>
          <div className="technology-list"> <span>
            <FaGlobe/>
            </span>
                    <label> 5 Years Experience </label>
                  </div>
          </Col>
          <Col lg={6} sm={12}>
          <div className="technology-list"> <span>
            <FaWaveSquare/>
            </span>
                    <label> Flexible Engagement Models </label>
                  </div>
          </Col>
          <Col lg={6} sm={12}>
          <div className="technology-list"> <span>
            <FaFileAlt/>
            </span>
                    <label> Strict NDA Terms </label>
                  </div>
          </Col>
          <Col lg={6} sm={12}>
          <div className="technology-list"> <span>
            <FaFileInvoice/>
            </span>
                    <label> No Hidden Costs </label>
                  </div>
          </Col>
          <Col lg={6} sm={12}>
          <div className="technology-list"> <span>
            <FaClock/>
            </span>
                    <label> On-Time Project Delivery </label>
                  </div>
          </Col>
        </Row>
                </Col>
                <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <ContactForm />
            </Col>
              </Row>
    </div>
          </section>
  <section className="all-company-feature mt-5 pt-5">
            <Container>
      <Row>
                <Col md={12} className="mt-4 mb-4" data-aos="fade-left">
                <h2>Our ERP Software Development Services</h2>
                <p> 
                With our bespoke ERP solutions, you can boost cross-departmental efficiency by streamlining your operational needs. Our ERP development services are driven by a desire to close the gaps between all critical company operations and bring them all together on a single, well-organized platform.
                </p>
                </Col>
              </Row>
    </Container>
          </section>



          <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
            <Container>
      <Row>
                <Col md={6}>
                <div className="company-slide mission-vission mission-vission2">
          <Row>
                    <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
              <FaStarOfLife/>
            </div>
                    </Col>
                    <Col md={9} className="ps-0">
                    <h5 className='p-0'>Migration And Upgradation</h5>
                    <p className='mb-0'>
                    With the help of our proficient PWA developers, we can migrate your existing application to PWA as per your requirements. We will follow a structured approach for migrating your application without impacting data security. We can help you upgrade your present PWA to its latest version so that the users will get a better experience. We will provide high-end migration services to convert your current apps to PWAs for offline access and better usability.
                       </p>
                    </Col>
                  </Row>
        </div>
                <div className="company-slide mission-vission mission-vission2">
          <Row>
                    <Col md={9} className="pe-0 text-end">
                    <h5 className='p-0'>Responsive Web App Development</h5>
                    <p className='mb-0'> Through our responsive app development services, 
                    we create faster PWAs with smooth interactivity across browsers for numerous devices. Our
                     knowledge can help you build well-designed websites that look attractive even on smaller 
                     devices. We are able to build websites that have a higher chance of being discovered by 
                     search engines and achieve better Google rankings. Utilize our expertise to create a custom
                      website that meets your company's demands while also appealing to your target audience.
                     </p>
                    </Col>
                    <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
              <FaSlideshare/>
            </div>
                    </Col>
                  </Row>
        </div>
                </Col>
                <Col md={6} className="mt-4" data-aos="fade-left">
                <img className='img-width' src={erpservice} alt="erpservice" />
                </Col>
              </Row>
      <Row>
                <Col md={6}>
                <div className="company-slide mission-vission mission-vission2 pb-5">
          <Row>
                    <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
              <FaScribd/>
            </div>
                    </Col>
                    <Col md={9} className="ps-0">
                    <h5 className='p-0'>ERP Development And Implementation</h5>
                    <p className='mb-0 pb-3'>
                    We can design, build, and implement customized ERP solutions that match the functional requirements of your business process. We will always maintain clear documentation of the software so that it can help a lot in applying future changes. Our all-encompassing ERP platforms will offer your company the advantages of data consistency and cost-effectiveness. We leverage state-of-the-art tools and techniques to provide standards-compliant solutions according to your business demands.
              
               </p>
                    </Col>
                  </Row>
        </div>
                </Col>
                <Col md={6}>
                <div className="company-slide mission-vission mission-vission2 pb-5">
          <Row>
                    <Col md={9} className="pe-0 text-end">
                    <h5 className='p-0'>System Configurations </h5>
                    <p className='mb-0'> We are adept at implementing target-specific configurations based on your requirements. We can help you adjust the parameters of your software to match the financial, language, shipping, and customer-facing needs of your company. We provide relevant configurations like automation of devices, artificial intelligence, enhanced user interfaces, and many others to give your business a competitive advantage. We will provide services to modify the configuration over time as per evolving business needs.
                       </p>
                    </Col>
                    <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
              <FaFileCode/>
            </div>
                    </Col>
                  </Row>
        </div>
                </Col>
              </Row>
      <Row>
                <Col md={6}>
                <div className="company-slide mission-vission mission-vission2">
          <Row>
                    <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
              <FaSearchengin/>
            </div>
                    </Col>
                    <Col md={9} className="ps-0">
                    <h5 className='p-0'>Data Warehouse </h5>
                    <p className='mb-0 pb-4'> We can assist you in integrating data from multiple sources that can be used for analysis and reporting. You can utilise the Data Warehouse to run queries and gain insight into both current and historical data. We can appropriately blend technologies and components that will help you to use the data efficiently. We can aid you in converting your data into meaningful information that will improve your business performance and growth. </p>
                    </Col>
                  </Row>
        </div>
                </Col>
                <Col md={6}>
                <div className="company-slide mission-vission mission-vission2 pb-5">
          <Row>
                    <Col md={9} className="pe-0 text-end">
                    <h5 className='p-0'>System Upgrades
</h5>
                    <p className='mb-0'> We will ensure that you are constantly one step ahead of your competitors by keeping your systems up-to-date. We can offer security updates to enhance the protection of your software products. On a regular basis, we will assess the existing situation and make the necessary improvements. We can provide reliable services to make your business run in the most recent software releases.
                    </p>
                    </Col>
                    <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
              <FaSolarPanel/>
            </div>
                    </Col>
                  </Row>
        </div>
                </Col>
              </Row>


              <Row>
                <Col md={6}>
                <div className="company-slide mission-vission mission-vission2">
          <Row>
                    <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
              <FaMoneyCheckAlt/>
            </div>
                    </Col>
                    <Col md={9} className="ps-0">
                    <h5 className='p-0'>Financial Management</h5>
                    <p className='mb-0 pb-4'> Our developers can build ERP solutions that provide better financial control by integrating business processes. We will focus on offering tools that assist you in budgeting, forecasting and planning. We can help you streamline your operations by crafting software that manages your financial data accurately and successfully. We can ensure that your application meets different safety & financial regulations so that you can increase your process efficiency and achieve financial wellness </p>
                    </Col>
                  </Row>
        </div>
                </Col>
                <Col md={6}>
                <div className="company-slide mission-vission mission-vission2 pb-5">
          <Row>
                    <Col md={9} className="pe-0 text-end">
                    <h5 className='p-0'>Supply Chain Management
</h5>
                    <p className='mb-0'> We will ensure that you are constantly one step ahead of your competitors by keeping your systems up-to-date. We can offer security updates to enhance the protection of your software products. On a regular basis, we will assess the existing situation and make the necessary improvements. We can provide reliable services to make your business run in the most recent software releases.
                    </p>
                    </Col>
                    <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
              <FaSuperpowers/>
            </div>
                    </Col>
                  </Row>
        </div>
                </Col>
              </Row>


              <Row>
                <Col md={6}>
                <div className="company-slide mission-vission mission-vission2">
          <Row>
                    <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
              <FaChartBar/>
            </div>
                    </Col>
                    <Col md={9} className="ps-0">
                    <h5 className='p-0'>Sales Management</h5>
                    <p className='mb-0 pb-3'> We are well-versed in creating ERP software with sales modules that can handle sales and distribution processes efficiently. With our feature-rich application, you can keep track of sales information like inventory availability, order status, shipping process & tracking, invoicing, etc. Our reliable ERP solution can help you take a customer-focused business plan and achieve better control over the sales department. We can help you become more responsive to your customers and increase productivity and profit. </p>
                    </Col>
                  </Row>
        </div>
                </Col>
                <Col md={6}>
                <div className="company-slide mission-vission mission-vission2 pb-5">
          <Row>
                    <Col md={9} className="pe-0 text-end">
                    <h5 className='p-0'>Customer Relationship Management
</h5>
                    <p className='mb-0'> We can precisely craft ERP solutions that manage your organisations’ interaction with 
                    current and potential customers. We can assist you in handling several business activities like recording 
                    customer interactions, sales tracking, creating/evaluating marketing campaigns, etc. We can help you analyze
                     data about customers’ history to improve the business relationship so that you can concentrate on customer 
                     retention to enhance sales growth. 
                    </p>
                    </Col>
                    <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
              <FaChalkboardTeacher/>
            </div>
                    </Col>
                  </Row>
        </div>
                </Col>
              </Row>


      <Row>
               
              </Row>
    </Container>
          </section>

          <section className="all-company-feature bg-gray bg-deep-gray  pt-2">
            <Container>
      <Row>
      
                <Col md={6} className="mb-4 mt-5 pt-4" data-aos="fade-right">
                <h2 className='mt-5'>What Makes Nexalinx Your Trusted<br/>
Partner In ERP Software Development?</h2>
                <p>
                We have great domain knowledge and expertise in ERP software development so we provide clients with high-quality services. We can help you integrate various functions that are required for the smooth running of your business process. We can provide highly-functional ERP software systems that streamline your organizational processes including human resources, financials, customer relationship management, supply chain management, manufacturing, etc. Our ERP solutions can provide insight into your business operations and assist you in making informed decisions that lead to better workflow and increased profitability.
                        
                         </p>
                </Col>
                <Col md={6} className="mt-5 mb-4" data-aos="fade-left">
                <img className="abouimg width90 mt-5" src={hirepwa} alt="hirepwa" />
                </Col>
              </Row>
    </Container>
          </section>
  <div className='clearfix'></div>
  <section className='mt-5'>
            <Container>
      
      <Row>
                <Col md={4}>
                <div className='benifit-block'> <span> <img className="pwabenefit" src={pwabenefit1} alt="pwabenefit1" /> </span>
          <h3>Dedicated Project Team</h3>
          <p> Our dedicated ERP developers can work for you on each of the engagement models and make sure that your project is completed in a timely manner. We are committed to providing reliable, scalable, and feature-rich ERP solutions. </p>
        </div>
                </Col>
                <Col md={4}>
                <div className='benifit-block'> <span> <img className="pwabenefit" src={pwabenefit2} alt="pwabenefit2" /> </span>
          <h3>Tailor-Made Applications</h3>
          <p>We can help you in attaining your organizational goals by building ERP applications that cater to your specific business requirements. We can aid you to optimize the whole business process by enhancing transparency within the industry. </p>
        </div>
                </Col>
                <Col md={4}>
                <div className='benifit-block'> <span> <img className="pwabenefit" src={pwabenefit3} alt="pwabenefit3" /> </span>
          <h3>Technical Proficiency
</h3>
          <p> We utilize the latest technology and comprehensive security measures throughout the development cycle to create high-quality ERP applications that can enhance your business efficiency </p>
        </div>
                </Col>
                <Col md={4}>
                <div className='benifit-block'> <span> <img className="pwabenefit" src={pwabenefit4} alt="pwabenefit4" /> </span>
          <h3>Advanced Toolkit
</h3>
          <p> We will leverage all the state-of-the-art tools and techniques available in the industry 
            to make the applications robust and engaging. We employ advanced security measures to make sure that
             the application is faultless and functioning seamlessly. </p>
        </div>
                </Col>
                <Col md={4}>
                <div className='benifit-block pb-5'> <span> <img className="pwabenefit" src={pwabenefit5} alt="pwabenefit5" /> </span>
          <h3>Budget-Friendly</h3>
          <p className='pb-3'> We will do a deep analysis of your business needs in order to precisely scope the project. We have created ERP applications for industries of different sizes and types while staying within their budget and industry standards. </p>
        </div>
                </Col>
                <Col md={4}>
                <div className='benifit-block'> <span> <img className="pwabenefit" src={pwabenefit6} alt="pwabenefit6" /> </span>
          <h3>Guaranteed Assistance
 </h3>
          <p> Our analyst will efficiently capture your operational data and scope the project to ensure that it is both cost-effective and completed on schedule. Our support engineers will offer maintenance and technical assistance once your ERP applications have been implemented. </p>
        </div>
                </Col>
              </Row>
    </Container>
          </section>








  <section className="all-company-feature bg-deep-gray mt-5 pt-5">
            <Container>
      <Row>
                <Col md={12} className="text-start mt-4 mb-4">
                <h2>Our Recent Works</h2>
                </Col>
              </Row>
      <Row>
                <OwlCarousel className='owl-theme slider-custom' {...options2} loop margin={20} nav>
          <div class='item'>
                    <div className='work-block-slide'> <img className='img-width-full' src={worka} alt="worka" /> </div>
                  </div>
          <div class='item'>
                    <div className='work-block-slide'> <img className='img-width-full' src={workb} alt="workb" /> </div>
                  </div>
          <div class='item'>
                    <div className='work-block-slide'> <img className='img-width-full' src={workc} alt="workc" /> </div>
                  </div>
          <div class='item'>
                    <div className='work-block-slide'> <img className='img-width-full' src={workd} alt="workd" /> </div>
                  </div>
          <div class='item'>
                    <div className='work-block-slide'> <img className='img-width-full' src={worke} alt="worke" /> </div>
                  </div>
          <div class='item'>
                    <div className='work-block-slide'> <img className='img-width-full' src={workf} alt="workf" /> </div>
                  </div>
        </OwlCarousel>
              </Row>
    </Container>
          </section>
  <section className="all-company-feature">
            <Container>
      <Row>
                <Col md={12} className="text-center mt-5 pt-5">
                <h2>Our Industry Experience</h2>
                </Col>
              </Row>
      <Row>
                <Col lg={4} sm={12}>
                <div className="company-feature industry-feature">
          <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu1} alt="indu1" /> </div>
          <h5 className="text-center mt-5">Ecommerce</h5>
          <p> The e-commerce solutions offered at Nexalinx are on-the-go and assured to bring benefits with increased ROI and overall productivity. We provide custom e-commerce development services and develop web apps for any kind of business across various industries and enterprises ranging from B2B and B2C trading networks to small, middle-sized, and large businesses. These custom solutions meet the user’s requirements by applying specific areas of specialization. </p>
        </div>
                </Col>
                <Col lg={4} sm={12}>
                <div className="company-feature industry-feature">
          <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu2} alt="indu2" /> </div>
          <h5 className="text-center mt-5">Healthcare</h5>
          <p> At Nexalinx, we help you develop healthcare software applications that automate processes such as patient management, lab management, test result analysis, and data collection. For the medical technology companies, from consulting to maintenance and support we always lent our supporting hands to tackle your technological dilemmas. </p>
        </div>
                </Col>
                <Col lg={4} sm={12}>
                <div className="company-feature industry-feature">
          <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu3} alt="indu3" /> </div>
          <h5 className="text-center mt-5">Logistics</h5>
          <p>The experts at Nexalinx help you build innovative and impressive tech-enabled logistics software applications to streamline the supply chain and provide flawless customer support. The warehouse management software also makes sure to enhance warehouse efficiency. The expert logistics and transportation management software offered by Nexalinx thereby help you do your tasks more effectively.</p>
        </div>
                </Col>
                <Col lg={4} sm={12}>
                <div className="company-feature industry-feature">
          <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu4} alt="indu4" /> </div>
          <h5 className="text-center mt-5">Education</h5>
          <p> In the education field, Nexalinx is engaged in integrated learning system software development, virtual learning management system, LMS software development, and even personalized e-learning software systems. Interactive, convenient, and cost-effective solutions are what we offer at Nexalinx. </p>
        </div>
                </Col>
                <Col lg={4} sm={12}>
                <div className="company-feature industry-feature">
          <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu5} alt="indu5" /> </div>
          <h5 className="text-center mt-5">Banking</h5>
          <p> From financial software development, custom banking software development, digital banking software services, and custom Fintech development, we at Nexalinx extend our software development experience in the industry of banking and finance as well. With custom features, and UI/UX designs we ensure the highest standards and security to the software developed. </p>
        </div>
                </Col>
                <Col lg={4} sm={12}>
                <div className="company-feature industry-feature">
          <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu6} alt="indu6" /> </div>
          <h5 className="text-center mt-5">Manufacturing</h5>
          <p> Implement manufacturing software for ensuring quality management and maintenance software including the application of intelligent products that help in accelerating the existing processes and bringing down operational costs. </p>
        </div>
                </Col>
              </Row>
    </Container>
          </section>
</Common>
            
          
      
    );
};

export default ErpSoftwareDevelopment;