
// eslint-disable-next-line
import React, { useState } from 'react'; 
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../layouts/Common"
import Carousel from 'react-bootstrap/Carousel';
import Form from 'react-bootstrap/Form';
import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { FaArrowRight } from 'react-icons/fa';
import worka from '../../assets/images/worka.png';
import workb from '../../assets/images/workb.png';
import workc from '../../assets/images/workc.png';
import workd from '../../assets/images/workd.png';
import worke from '../../assets/images/worke.png';
import workf from '../../assets/images/workf.png';

import { FaArrowAltCircleRight } from 'react-icons/fa';
import hire from '../../assets/images/hire.svg';
import angular from '../../assets/images/ani.svg';
import reacjs from '../../assets/images/reacjs.svg';
import pay from '../../assets/images/pay.svg';
import tech from '../../assets/images/tech.svg';
import wez from '../../assets/images/wez.svg';
import php from '../../assets/images/php.svg';
import node from '../../assets/images/node.svg';
import lara from '../../assets/images/lara.svg';
import mong from '../../assets/images/mong.svg';
import soft from '../../assets/images/soft.svg';
import appd from '../../assets/images/appd.svg';
import carta from '../../assets/images/carta.svg';
import codin from '../../assets/images/codin.svg';
import ios from '../../assets/images/ios.svg';
import avater from '../../assets/images/avater.svg';
import awslogo from '../../assets/images/awslogo.png';
import googlepartner from '../../assets/images/googlepartner.png';
import mslogo from '../../assets/images/mslogo.jpg';



import projectc from '../../assets/images/projectc.svg';
import brain from '../../assets/images/brain.svg';
import countires from '../../assets/images/countires.svg';
import experience from '../../assets/images/experience.svg';
import partners from '../../assets/images/partners.svg';


import bootstrap from '../../assets/images/bootstrap.svg';
import ember from '../../assets/images/ember.svg';
import manufacturing from '../../assets/images/manufacturing.svg';
import logistics from '../../assets/images/logistics.svg';
import healthcare from '../../assets/images/healthcare.svg';
import aviation from '../../assets/images/aviation.svg';
import ecommerce from '../../assets/images/ecommerce.svg';
import content from '../../assets/images/content.svg';
import wp from '../../assets/images/wp.svg';
import as from '../../assets/images/as.svg';
import bs from '../../assets/images/bs.svg';
import about from '../../assets/images/about.svg';
import leftimg from '../../assets/images/leftimg.svg';
import rightimfe from '../../assets/images/rightimfe.svg';
import client from '../../assets/images/client.svg';
import sof1 from '../../assets/images/sof1.svg';

// import getques from '../../assets/images/getques.svg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import FreeQuote from '../FreeQuote';
import Modal from 'react-bootstrap/Modal';
import { async } from 'q';
import { toast } from 'react-hot-toast';







const Home = () => {
  const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
const baseURL = process.env.REACT_APP_BASE_URL;

  
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 2,
        },
        1000: {
            items:4,
        }
    },
  };

  const options2 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items:1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 2,
        },
        1000: {
            items:3,
        }
    },
  };
  const options3 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 2,
        },
        400: {
            items:2,
        },
        600: {
            items: 3,
        },
        700: {
            items: 3,
        },
        1000: {
            items:3,
        }
    },
  };

  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    let formdata = new FormData();
    formdata.append("date", inputs?.scheduleDate ?? "");
    formdata.append("time", inputs?.scheduleTime ?? "");
    formdata.append("name", inputs?.name ?? "");
    formdata.append("email", inputs?.email ?? "");
    formdata.append("phone", inputs?.phone ?? "");
    formdata.append("message", inputs?.comment ?? "");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
    };

    try {
      const res = await fetch(
        `${baseURL}/api/schedule/meeting/form`,
        requestOptions
      ).then((response) => response.json());

      if (res?.status && res?.code === 200) {
        setInputs({});
        return toast.success(res?.message);
      } else {
        return toast.error("Message not sent!");
      }
    } catch (error) {
      return toast.error("Message not sent!");
    }
  };


    return (
<Common>
  <section className="banner-section">
    <Container>
      <Row>
        <Col md={12}>
        <div className="slider-banner slide-arrow">
        {/* fade */}
          <Carousel>
            <Carousel.Item>
              <h1 className="text-white"><strong>IT Consulting</strong></h1>
              <p className="text-white p20">As a leading IT consulting firm, we help businesses to reinvent and <br/>
                excel by establishing complete relianceon information technology and <br/>
                offering high quality technology consulting services. </p>
                
                <NavLink exact to="/it-consulting-services">
              <Button variant="primary btn-custom mt-3 btn-big">Read More
              <FaArrowRight/>
              </Button>
              </NavLink>
              {/* <section className="service-slider-block">
                <div className="block-item"> <span>
                  <FaFileSignature/>
                  </span>
                  <p className="text-white">No Contract Lock-ins</p>
                </div>
                <div className="block-item"> <span>
                  <FaMoneyCheckAlt/>
                  </span>
                  <p className="text-white">No Upfront Costs</p>
                </div>
                <div className="block-item"> <span>
                  <FaPaperPlane/>
                  </span>
                  <p className="text-white">On Time Delivery</p>
                </div>
                <div className="block-item"> <span>
                  <FaIdCardAlt/>
                  </span>
                  <p className="text-white">Continuity of Business</p>
                </div>
              </section> */}
            </Carousel.Item>
            <Carousel.Item>
              <h1 className="text-white"><strong>Hire Dedicated Developers</strong></h1>
              <p className="text-white p20">Nexalinx solutions is a leading offshore development partner with offices in <br/>
                Bangladesh, USA and Australia. Our deep understanding about the offshore<br/>
                software outsourcing makes us a unique solution provider.</p>
                <NavLink exact to="/hire-dedicated-software-developers-programmers">
              <Button variant="primary btn-custom mt-3 btn-big">Read More
              <FaArrowRight/>
              </Button>
              </NavLink>
              {/* <section className="service-slider-block">
                <div className="block-item"> <span>
                  <FaCalendarAlt/>
                  </span>
                  <p className="text-white">Time Zone Advantages</p>
                </div>
                <div className="block-item"> <span>
                  <FaDesktop/>
                  </span>
                  <p className="text-white">Latest Technology</p>
                </div>
                <div className="block-item"> <span>
                  <FaMoneyCheckAlt/>
                  </span>
                  <p className="text-white">Cost Effective</p>
                </div>
                <div className="block-item"> <span>
                  <FaDelicious/>
                  </span>
                  <p className="text-white">Strict NDA Terms</p>
                </div>
              </section> */}
            </Carousel.Item>
          </Carousel>
        </div>
        </Col>
      </Row>
    </Container>
  </section>

  

  <section className="all-company-feature">
    <Container>
      <Row>
        <Col md={12} className="text-center pt-3">
        <h4 className="mt-5">SERVICES</h4>
        <h2>Our Services</h2>
        </Col>
      </Row>
      <Row>
        <Col lg={4} sm={12}>
        <div className="company-feature"> <span data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={hire} alt="hire" /></span>
          <h5 className="text-center mt-5">Hire & Offshore</h5>
          <p> Looking for trusted experienced and professional 
            services take. our offshore services or hire developers 
            from us for your development. </p>
          <ul className="ul-feature">
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>Offshore Development Services</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>Hire Dedicated Developers</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>QA/Testing Services</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>IT Consulting</p>
            </li>
          </ul>
        </div>
        </Col>
        <Col lg={4} sm={12}>
        <div className="company-feature"> <span data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={soft} alt="soft" /></span>
          <h5 className="text-center mt-5">Frontend Development</h5>
          <p> Incorporate the latest of interactivity, multimedia and user interface on your website or web application with our services.</p>
          <ul className="ul-feature">
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>Angular JS Development</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>React JS Development</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>Vue JS Development</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>Node JS Development</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>Progressive Web App Development</p>
            </li>
          </ul>
        </div>
        </Col>
        <Col lg={4} sm={12}>
        <div className="company-feature"> <span data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={sof1} alt="sof1" /></span>
          <h5 className="text-center mt-5">Software & Web Development</h5>
          <p> Get custom offshore software development and web development services from us, incorporate as many features you imagine and we will incorporate them for you. </p>
          <ul className="ul-feature">
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>Asp.Net / MVC Development</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>C# / WPF Development</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>Windows Azure Development</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>PHP Web Development</p>
            </li>
          </ul>
        </div>
        </Col>
        <Col lg={4} sm={12}>
        <div className="company-feature"> <span data-aos="fade-right" data-aos-offset="320" data-aos-easing="ease-in-sine"><img src={appd} alt="appd" /></span>
          <h5 className="text-center mt-5">Mobile App Development</h5>
          <p>Our mobile application development service can develop mobile apps for your business that come with the latest of features and user interface.</p>
          <ul className="ul-feature">
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p> Android Development</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>iOS Development</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>Xamarin App Development</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>React Native</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>Vue Native</p>
            </li>
          </ul>
        </div>
        </Col>
        <Col lg={4} sm={12}>
        <div className="company-feature"> <span data-aos="fade-right" data-aos-offset="320" data-aos-easing="ease-in-sine"><img src={carta} alt="carta" /></span>
          <h5 className="text-center mt-5">E-Commerce</h5>
          <p>We transform your business idea into an online store to make it reach globally to your target customers increasing your revenue. </p>
          <ul className="ul-feature">
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>Custom Ecommerce Development</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>Magento Development</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p>Open Cart Development</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p> Zen Cart Development</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p> OS Commerce Development</p>
            </li>
          </ul>
        </div>
        </Col>
        <Col lg={4} sm={12}>
        <div className="company-feature"> <span data-aos="fade-right" data-aos-offset="320" data-aos-easing="ease-in-sine"><img src={content} alt="content" /></span>
          <h5 className="text-center mt-5">Content Management</h5>
          <p>We deliver smart content management solutions driven with a solid architecture, secure functionalities, and responsive design.</p>
          <ul className="ul-feature">
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p> Custom CMS Solutions</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p> WordPress Development</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p> Drupal Development</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p> Joomla Development</p>
            </li>
            <li>
              <FaArrowAltCircleRight className="icon-list"/>
              <p> Salesforce Development</p>
            </li>
          </ul>
        </div>
        </Col>
      </Row>


      <Row>
        <Col md={12} className="text-center mt-4">
        <Button variant="primary btn-custom mt-3 btn-big w260" onClick={handleShow}>Get a Free Quote
        <FaArrowRight className="ms-5" />
        </Button>
        </Col>
      </Row>
    </Container>



 
  <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
    <Modal.Header closeButton className='border-bottom-0'>
    </Modal.Header>
    <Modal.Body>
      <FreeQuote/>
    </Modal.Body>
  </Modal>

  </section>
  <section className="all-company-feature frame-bg mtp">
    <Container>
      <Row>
        <Col md={12} className="text-center">
        <h4>OUR TECHNOLOGIES</h4>
        <h2>Technologies</h2>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
        <ul className="ul-tech-list">
        <NavLink exact to="/angular" >
          <li data-aos="fade-right">
             <span><img src={angular} alt="angular" /></span>
            <p>Angularjs</p>
          </li>
          </NavLink>
          <li data-aos="fade-right"> <span><img src={reacjs} alt="reacjs" /></span>
            <p>Reactjs</p>
          </li>
          <li data-aos="fade-right"> <span><img src={pay} alt="pay" /></span>
            <p>Python</p>
          </li>
          <li data-aos="fade-right"> <span><img src={tech} alt="tech" /></span>
            <p>ASP.NET MVC</p>
          </li>
          <li data-aos="fade-right" className="border-end-0"> <span><img src={wez} alt="wez" /></span>
            <p>Vue JS</p>
          </li>
          <li data-aos="fade-left" className="border-bottom-0"> <span><img src={php} alt="php" /></span>
            <p>PHP</p>
          </li>
          <li data-aos="fade-left" className="border-bottom-0"> <span><img src={node} alt="node" /></span>
            <p>Node.js</p>
          </li>
          <li data-aos="fade-left" className="border-bottom-0"> <span><img src={lara} alt="lara" /></span>
            <p>Laravel</p>
          </li>
          <li data-aos="fade-left" className="border-bottom-0"> <span><img src={mong} alt="mong" /></span>
            <p>Magento</p>
          </li>
          <li data-aos="fade-left" className="border-bottom-0 border-end-0"> <span><img src={wp} alt="wp" /></span>
            <p>Wordpress</p>
          </li>
          <li data-aos="fade-right" className="border-bottom-0 border-top-do"> <span><img src={codin} alt="codin" /></span>
            <p>Codeignitor</p>
          </li>
          <li data-aos="fade-right" className="border-bottom-0 border-top-do"> <span><img src={ios} alt="ios" /></span>
            <p>iOS</p>
          </li>
          <li data-aos="fade-right" className="border-bottom-0 border-top-do"> <span><img src={appd} alt="appd" /></span>
            <p>Android</p>
          </li>
          <li data-aos="fade-right" className="border-bottom-0 border-top-do"> <span><img src={bootstrap} alt="bootstrap" /></span>
            <p>Bootstrap</p>
          </li>
          <li data-aos="fade-right" className="border-bottom-0 border-end-0 border-top-do"> <span><img src={ember} alt="ember" /></span>
            <p>Ember</p>
          </li>
        </ul>
        </Col>
      </Row>
    </Container>
  </section>
  <section className="all-company-feature mtp">
    <Container className="bg-gray">
      <Row>
        <Col md={6} className="schedu-bg p-0" data-aos="fade-down-right">
        <div className="block-schedue">
          <h6 className="text-white">SCHEDULE MEETING</h6>
          <h5 className="text-white mt-4">Schedule A Custom 20 Min Consultation</h5>
          <p className="text-white mt-1"> Contact us today to schedule a free, 20-minute call to learn how NexaLINK Solutions
            can help you revolutionize the way your company conducts business. </p>
        </div>
        </Col>
        <Col md={6} className="form-area"  data-aos="fade-down-left">
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3 me-0 sch-block">
            <h3 className="h3-schedule mb-4">Schedule Meeting</h3>
            <Form.Group as={Col} className="ps-0">
              <Form.Label>Date</Form.Label>
              <Form.Control 
                type="date" 
                name="scheduleDate" 
                value={inputs.scheduleDate || ""} 
                onChange={handleChange}
                placeholder="MM/DD/YYYY"
                required
              />
            </Form.Group>
            <Form.Group as={Col} className="pe-0 spaceres">
              <Form.Label>Time</Form.Label>
              <Form.Control 
                type="time" 
                name='scheduleTime'
                value={inputs.scheduleTime || ""} 
                onChange={handleChange}
                placeholder="HH:MM AM/PM"
                required
              />
            </Form.Group>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control 
              type="text" 
              name="name" 
              value={inputs.name || ""} 
              onChange={handleChange}
              placeholder="" 
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="">
            <Form.Label>Email</Form.Label>
            <Form.Control 
              type="email" 
              name="email"
              value={inputs.email || ""} 
              onChange={handleChange}
              placeholder=""
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Contact number</Form.Label>
            <Form.Control 
              type="text" 
              name="phone" 
              value={inputs.phone || ""} 
              onChange={handleChange}
              placeholder="" 
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Message</Form.Label>
            <Form.Control 
              as="textarea" 
              name="comment"
              value={inputs.comment || ""} 
              onChange={handleChange}
              className="textform" 
              rows={3}
              required
            />
          </Form.Group>
          <Col md={12} className="text-center mt-4">
          <Button variant="primary btn-custom mt-3 btn-big" type="submit">Submit
          <FaArrowRight className="ms-5" />
          </Button>
          </Col>
        </Form>
        </Col>
      </Row>
    </Container>
  </section>
  <section className="all-company-feature mt-4 pt-5">
    <Container>
      <Row>
        <Col md={12} className="text-start mb-4">
        <h4>INDUSTRY EXPERTISE </h4>
        <h2>Our Industry Experience</h2>
        </Col>
      </Row>
      <Row>

      <OwlCarousel className='owl-theme slider-custom custom-industry' {...options} loop margin={20} nav>

      <div class='item'>
      <h4>
      <div className="company-slide">
      <div className="slider-icon"> <img src={manufacturing} alt="manufacturing" /> </div>
      <h5>Manufacturing</h5>
      </div>
      </h4>
      </div>
      <div class='item'>
      <div className="company-slide bg3">
      <div className="slider-icon"> <img src={logistics} alt="logistics" /> </div>
      <h5>logistics</h5>
      </div>
      </div>
      <div class='item'>
      <div className="company-slide bg1">
      <div className="slider-icon"> <img src={healthcare} alt="healthcare" /> </div>
      <h5>Healthcare</h5>
      </div>
      </div>
      <div class='item'>
      <div className="company-slide bg2">
      <div className="slider-icon"> <img src={aviation} alt="aviation" /> </div>
      <h5>Aviation</h5>
      </div>
      </div>

      <div class='item'>
      <div className="company-slide">
      <div className="slider-icon"> <img src={ecommerce} alt="ecommerce" /> </div>
      <h5>Ecommerce</h5>
      </div>
      </div>

      </OwlCarousel>

   
      </Row>
    </Container>
  </section>
  <section className="all-company-feature pt-2">
    <Container>
      <Row>
        <Col md={6} className="mb-4" data-aos="fade-right">
        <h4 className="mt-5 pt-4">OUR HISTORY </h4>
        <h2>About us</h2>
        <p>
        At Nexalinx, we believe in the power of technology to transform businesses. As a software company specializing in IT solutions and services, we help businesses of all sizes leverage technology to improve their operations and increase their efficiency.
<br/>
Since our inception, we have been committed to providing our clients with the best possible software solutions and services. We understand that every business is unique, and we work closely with our clients to understand their specific needs and requirements. This allows us to develop customized software solutions that are tailored to the unique needs of each business.
          </p>
       
        <Col md={12} className="mt-4">
          <NavLink exact to="/about-us">
        <Button variant="primary btn-custom mt-3 btn-big">Read Details
        <FaArrowRight className="ms-5" />
        </Button>
        </NavLink>
        </Col>
        </Col>
        <Col md={6} className="mt-5 mb-4" data-aos="fade-left">
        <img className="abouimg mt-4" src={about} alt="about" />
        </Col>
      </Row>
    </Container>
  </section>
  <section className="all-company-feature bg-deep-gray bg-deep-gray2 mt-3 pt-3 certification-slide">
    <Container>
      <Row>
        <Col md={6} className="mt-5 mb-4 pb-5 pb-res" data-aos="flip-up">
        {/* <h4 className="mt-5 pt-4">CERTIFICATIONS </h4> */}
        <h2 className='mt-5'>Technology Partner</h2>
        <p> We keep deepening the expertise to meet your highest expectations and <br/>
          build even more innovative software. </p>
        </Col>
        <Col md={6} className="mt-4 mb-4">

        <OwlCarousel className='owl-theme slider-custom slider-custom-two slide-img2' {...options3} loop margin={20} nav>
        <div class='item'>
          <div className="company-slide">
            <div className="slider-icon2"> <img src={awslogo} alt="awslogo" /> </div>
        </div>
        </div>
        <div class='item'>
          <div className="company-slide">
          <div className="slider-icon2"> <img src={googlepartner} alt="googlepartner" /> </div>
        </div>
        </div>
        <div class='item'>
          <div className="company-slide">
          <div className="slider-icon2"> <img src={mslogo} alt="mslogo" /> </div>
        </div>
        </div>
        <div class='item'>
          <div className="company-slide">
            <div className="slider-icon2"> <img src={awslogo} alt="awslogo" /> </div>
        </div>
        </div>
        <div class='item'>
          <div className="company-slide">
          <div className="slider-icon2"> <img src={googlepartner} alt="googlepartner" /> </div>
        </div>
        </div>
        <div class='item'>
          <div className="company-slide">
          <div className="slider-icon2"> <img src={mslogo} alt="mslogo" /> </div>
        </div>
        </div>
</OwlCarousel>


      
        </Col>
      </Row>
    </Container>
  </section>
  <section className="all-company-feature pt-3">
    <Container>
      <Row>
        <Col md={6} className="mt-4 mb-4" data-aos="fade-right">
        <div className='company-block-feature-two'> <img src={leftimg} alt="leftimg" /> </div>
        </Col>
        <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
        <h4 className="mt-2 pt-4">WHO WE ARE </h4>
        <h2>Offshore Software<br/>
          DevelopmentCompany In USA</h2>
        <p> We  Nexalinx Solutions is an offshore development company in USA that you can trust for quality and reliability.
          
          We have been delivering high quality, scalable and cost effective offshore software development and web solutions to a global clientele since 2005 with full professionalism, skill and expertise to bring smile on faces of our clients by providing best ROI to them in time.
          
          When we work for you we ensure that your goals and objectives are in our top priority. As a reputed software outsourcing company in USA, we strategize, develop and execute all our tasks with sensibility and perfection. Our team of professionals will provide you everything needed to make a successful product delivered to you. When you work with us, your goals and objectives are in the hands of experts who will help you strategize, develop and execute your plans perfectly.
          
          With a focus on providing custom offshore software development and web application development we are working on all the platforms and are up to date on the latest technology that will surpass your expectations.
          
          We design and develop Software solutions for our customers. we share our knowledge and expertise of our team of experts who can provide everything to make your business successful and grow together with you.
          
          We provide software development services to many of our clients spread across the globe and have gained high reputation in offshore software development industry. Hire software developers from the pool of our professional for a very affordable cost and get the feel of interaction and support of being your own team. </p>
        {/* <Col md={12} className="mt-4">
        <Button variant="primary btn-custom mt-3 btn-big">Read Details
        <FaArrowRight className="ms-5" />
        </Button>
        </Col> */}
        </Col>
      </Row>
    </Container>
  </section>
  <Col md={12} className="mt-4">
  <hr className='hr' />
  </Col>
  <section className="all-company-feature pt-3 mb-5">
    <Container>
      <Row>
        <Col md={6} className="mt-4 mb-4">
        <div className="company-slide mission-vission" data-aos="flip-left">
          <Row>
            <Col md={3}>
            <div className="slider-icon"> <img src={as} alt="as" /> </div>
            </Col>
            <Col md={9} className="ps-0">
            <h5 className='p-0'>Our Vision</h5>
            <p className='mb-0'> Across the globe, the Enterprise ecosystem is evolving. This has created a new scenario for businesses operating in the market.… </p>
            <NavLink exact to="/vision">Read more...</NavLink>
            </Col>
          </Row>
        </div>
        <div className="company-slide mission-vission mt-4" data-aos="flip-right">
          <Row>
            <Col md={9} className="pe-0 text-end">
            <h5 className='p-0'>Our Mission</h5>
            <p className='mb-0'> SAcross the globe, the Enterprise ecosystem is evolving. This has created a new scenario for businesses operating in the market. You either have robus… </p>
            <NavLink exact to="/mission">Read more...</NavLink>
            </Col>
            <Col md={3}>
            <div className="slider-icon"> <img src={bs} alt="bs" /> </div>
            </Col>
          </Row>
        </div>
        </Col>
        <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
        <div className='company-block-feature-two position-img'> <img src={rightimfe} alt="rightimfe" /> </div>
        </Col>
      </Row>
    </Container>
  </section>
  <section className="all-company-feature mtp">
    <Container>
      <Row>
        <Col md={5} data-aos="fade-right">
        <img className='img-client mt-4' src={client} alt="hire" />
        </Col>
        <Col md={7} data-aos="fade-left">
        <Row>
          <Col md={12}>
          <h4>CLIENTS' FEEDBACK</h4>
          <h2>Testimonials</h2>
          </Col>
        </Row>
        <Carousel fade className="slider-custom slider-custom-two display-none-slide">
          <Carousel.Item>
            <Row>
              <Col md={12}>
              <div className='client-block'>
                <div className='client-top'> <img src={avater} alt="avater" className='client-av' />
                  <div className='client-details'>
                    <h6 className='mb-1'>Richard Roth</h6>
                    <label> United States </label>
                  </div>
                </div>
                <p className='detail-message'> We have been working with Nexalinx Solutions USA Pvt Ltd from last year and now for a very complicated project that we are in, with lot of software technologies involved. Nexalinx Solutions USA has matched up to our expectations and the deliverables were in time. </p>
              </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col md={12}>
              <div className='client-block'>
                <div className='client-top'> <img src={avater} alt="avater"  className='client-av' />
                  <div className='client-details'>
                    <h6 className='mb-1'>Swan Atkins</h6>
                    <label> United States </label>
                  </div>
                </div>
                <p className='detail-message'>The Nexalinx team has always promptly returned my calls or emails no matter what the time is. I've found them to be consistently available and committed to addressing my needs. I've already recommended them many times and I will continue to do so. </p>
              </div>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
        </Col>
      </Row>
    </Container>
  </section>


  <section className="all-company-feature bg-deep-gray mt-5 pt-5">
    <Container>
      <Row>
        <Col md={12} className="text-start mt-4 mb-4">
        <h2>Our Recent Works</h2>
        </Col>
      </Row>
      <Row>

  <OwlCarousel className='owl-theme slider-custom' {...options2} loop margin={20} nav>
  
    <div class='item'>
    <div className='work-block-slide'> <img className='img-width-full' src={worka} alt="worka" /> </div>
    </div>
    <div class='item'>
    <div className='work-block-slide'> <img className='img-width-full' src={workb} alt="workb" /> </div>
    </div>
    <div class='item'>
    <div className='work-block-slide'> <img className='img-width-full' src={workc} alt="workc" /> </div>
    </div>
    <div class='item'>
    <div className='work-block-slide'> <img className='img-width-full' src={workd} alt="workd" /> </div>
    </div>

    <div class='item'>
    <div className='work-block-slide'> <img className='img-width-full' src={worke} alt="worke" /> </div>
    </div>
    <div class='item'>
    <div className='work-block-slide'> <img className='img-width-full' src={workf} alt="workf" /> </div>
    </div>
    
</OwlCarousel>




      </Row>
    </Container>
  </section>
<div className='clearfix'></div>
  <Container>
  <div className='comapny-status'>
    <Row>
      <Col md={12} className="text-center">
        <div className='company-status-block'>
      <img  src={partners} alt="partners" />
      <h3>12+</h3>
      <p>Tech Partners</p>
      </div>
     
      <div className='company-status-block'>
      <img  src={experience} alt="experience" />
      <h3>10+</h3>
      <p>Years of Experience</p>
      </div>

      <div className='company-status-block'>
      <img  src={countires} alt="countires" />
      <h3>15+</h3>
      <p>Countries Served</p>
      </div>

      <div className='company-status-block'>
      <img  src={brain} alt="brain" />
      <h3>100+</h3>
      <p>IT Professionals</p>
      </div>
      <div className='company-status-block'>
      <img  src={projectc} alt="projectc" />
      <h3>500+</h3>
      <p>Projects Completed</p>
      </div>
      </Col>
    </Row>
  </div>

  </Container>

  {/* <section className="all-company-feature mtp">
    <Container>
      <Row>
        <Col md={12} className="text-center">
        <h4>LATEST BLOGS</h4>
        <h2>Latest From Our Blogs</h2>
        </Col>
      </Row>
      <Row className='mt-4 pt-3'>
        <Col md={4} data-aos="zoom-in">
        <div className='block-list'>
          <div className='block-banner'> <img src={block0} alt="block0" />
            <div className='block-status'>
              <Row>
                <Col md={6}>
                <FaCalendarAlt/>
                Jule 26, 2022
                </Col>
                <Col md={6}>
                <FaCommentDots/>
                Comments (23)
                </Col>
              </Row>
            </div>
          </div>
          <div className='p-4'>
            <h3 className='mt-3 mb-1'>How to Create a Responsive Website Using Full-Stack Development</h3>
            <div className='client-top mt-4'> <img src={clientimg} alt="clientimg" className='client-av' />
              <div className='client-details width70'>
                <label> <i>Post By</i> </label>
                <h6 className='mb-1'>Jone Doe</h6>
              </div>
            </div>
            <div className='clearfix'></div>
            <Col md={12} className="mt-4">
            <p>The need for outsourced software development is growing along with the relevance of software development to businesses. </p>
            <NavLink exact to="">Read more...</NavLink>
            </Col>
          </div>
        </div>
        </Col>
        <Col md={4} data-aos="zoom-in">
        <div className='block-list'>
          <div className='block-banner'> <img src={block1} alt="block1" />
            <div className='block-status'>
              <Row>
                <Col md={6}>
                <FaCalendarAlt/>
                Jule 26, 2022
                </Col>
                <Col md={6}>
                <FaCommentDots/>
                Comments (23)
                </Col>
              </Row>
            </div>
          </div>
          <div className='p-4'>
            <h3 className='mt-3 mb-1'>Why ReactJS is a Better Choice for Enterprise App Development</h3>
            <div className='client-top mt-4'> <img src={clientimg2} alt="clientimg2" className='client-av' />
              <div className='client-details width70'>
                <label> <i>Post By</i> </label>
                <h6 className='mb-1'>Najir Sams</h6>
              </div>
            </div>
            <div className='clearfix'></div>
            <Col md={12} className="mt-4">
            <p>The development of an enterprise application that precisely matches your company's needs might drive growth overall tremendously. </p>
            <NavLink exact to="">Read more...</NavLink>
            </Col>
          </div>
        </div>
        </Col>
        <Col md={4} data-aos="zoom-in">
        <div className='block-list'>
          <div className='block-banner'> <img src={block2} alt="block2" />
            <div className='block-status'>
              <Row>
                <Col md={6}>
                <FaCalendarAlt/>
                Jule 26, 2022
                </Col>
                <Col md={6}>
                <FaCommentDots/>
                Comments (23)
                </Col>
              </Row>
            </div>
          </div>
          <div className='p-4'>
            <h3 className='mt-3 mb-1'>Flutter Or React Native? Which Is More Advantageous</h3>
            <div className='client-top mt-4'> <img src={user3} alt="user3" className='client-av' />
              <div className='client-details width70'>
                <label> <i>Post By</i> </label>
                <h6 className='mb-1'>Thomash Jary</h6>
              </div>
            </div>
            <div className='clearfix'></div>
            <Col md={12} className="mt-4">
            <p> The market for mobile app development is expanding quickly, especially due to COVID-19's need for specialized lifestyle and </p>
            <NavLink exact to="">Read more...</NavLink>
            </Col>
          </div>
        </div>
        </Col>
        <Col md={12} className="mt-4 text-center mt-4">
        <Button variant="primary btn-custom mt-3 btn-big">See All
        <FaArrowRight className="ms-5" />
        </Button>
        </Col>
      </Row>
    </Container>
  </section> */}

</Common>
);
};

export default Home;