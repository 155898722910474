// import Container from 'react-bootstrap/Container';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React from 'react';
import Common from "../../layouts/Common";
// import Container from 'react-bootstrap/Container';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { FaArrowRight, FaCheckCircle, FaClock, FaCommentAlt, FaCommentDots, FaFileAlt, FaFileCode, FaFileInvoice, FaGlobe, FaLaptop, FaLockOpen, FaMobileAlt, FaScrewdriver, FaSearchengin, FaServer, FaSolarPanel, FaSoundcloud, FaUserAlt, FaWaveSquare } from 'react-icons/fa';
import indcom from '../../assets/images/indcom.svg';
import serviceall from '../../assets/images/serviceall.svg';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ContactForm from '../../components/ContactForm';
import IndustryExperience from '../../components/IndustryExperience';
import RecentWork from '../../components/RecentWork';



const Laravel = () => {

  const iconList = [FaArrowRight, FaCheckCircle, FaClock, FaCommentAlt, FaCommentDots, FaFileAlt, FaFileCode, FaFileInvoice, FaGlobe, FaLaptop, FaLockOpen, FaMobileAlt, FaScrewdriver, FaSearchengin, FaServer, FaSolarPanel, FaSoundcloud, FaUserAlt, FaWaveSquare];
  const iconList2 = ['FaArrowRight', 'FaCheckCircle', 'FaClock', 'FaCommentAlt', 'FaCommentDots', 'FaFileAlt', 'FaFileCode', 'FaFileInvoice', 'FaGlobe', 'FaLaptop', 'FaLockOpen', 'FaMobileAlt', 'FaScrewdriver', 'FaSearchengin', 'FaServer', 'FaSolarPanel', 'FaSoundcloud', 'FaUserAlt', 'FaWaveSquare'];

  const serviceData =
  {
    "title": "Laravel Web Development Company in Bangladesh",
    "subTitle": "Nexalinx Solutions Pvt Ltd has a dedicated team to work on the Laravel PHP framework, whether it is a small add-on or an extension we are ready to offer our best Laravel development service in Bangladesh to our clients. Laravel framework is only three years old but it has been developed with a focus, it is a versatile framework compared to the other frameworks of PHP. Laravel framework has robust features and a better code foundation. It's easy to hire Laravel developers from Nexalinx to take advantage of these features for application deployment and maintenance and ultimately offer our customers wholesome skilled services in Laravel.",
    "feature": [
      {
        "title": "SECURITY & IP PROTECTION",
        "icon": "FaLockOpen"
      },
      {
        "title": "1 YEARS EXPERIENCE",
        "icon": "FaGlobe"
      },
      {
        "title": "FLEXIBLE ENGAGEMENT MODELS",
        "icon": "FaWaveSquare"
      },
      {
        "title": "STRICT NDA TERMS",
        "icon": "FaFileAlt"
      },
      {
        "title": "NO HIDDEN COSTS",
        "icon": "FaFileInvoice"
      },
      {
        "title": "ON-TIME PROJECT DELIVERY",
        "icon": "FaClock"
      }
    ],
    "heading_one": {
      "title": "Offshore Laravel Web Development Service",
      "details": "Laravel is a PHP framework built with the attention of writing code syntax which will be simple and lucid. This will provide the advantage of having a web application that will get developed friendly and code that is maintainable. Create a super web application with robustuous features and highly mangeable using one of the best PHP web application framework. We specialize in reliable and scalable Laravel web application development. We don’t just create solid codes for our clients using Laravel. We also clean codes and write APIs for easy maintenance of codes and version management. Due to Laravel’s nature, multiple programmers can work together on the project for more efficient and faster results. Our developers possess excellent readability and understanding of source codes, allowing them to develop high quality applications using Laravel.",
      "image": "https://www.image.com"
    },
    "heading_two": {
      "title": "Advantages of outsourcing",
      "details": "",
      "feature_point": [
        {
          "title": "",
          "icon": "FaCheckCircle"
        }
      ],
      "image": "https://www.image.com"
    },
    "service_items": {
      "title": "Laravel Framework Development Service We Offer",
      "subTitle": "",
      "image": "https://www.image.com",
      "items": [
        {
          "title": "Laravel Web Development",
          "details": "As a renowned Laravel web development company in Bangladesh, we develop aesthetically appealing, bug-free, and feature-rich web applications to help our clients build a stronger online presence and deliver unforgettable web experiences to their users. Using the Laravel PHP framework for web development, we build business websites, web applications, backend, Restful API, web services, ERP, CRM, CMS, POS systems, SaaS, cloud-based software, etc. Personalized and customized web applications are developed by us using features of Laravel such as a modular packaging system with a dedicated dependency manager. With customized RESTful APIs, integration, and custom dashboards, we explore various Laravel features to cater to areas like eCommerce, payment, and much more. We can develop intuitive styles, themes, designs, and interfaces for your web solution with extremely enhanced page structures and a deep understanding of your industry vertical, user type, and business requirements. We are also involved in module development to build Laravel applications healthier and easier to maintain."
        },
        {
          "title": "Laravel Mobile App Development",
          "details": "Our Laravel development company in Bangladesh can provide you with the services needed to develop your mobile application using a PHP framework like Laravel. We are efficient in all segments of mobile development projects from ideation to implementation. Although Laravel is majorly used in web development, the framework has numerous tools and easy code syntax that is best suited for designing backend systems for mobile apps. Our team has experience in using Laravel for back-end development and AngularJs for frontend development. We provide Laravel mobile development services for mobile apps on all platforms including iOS and Android. We can build you a fully-featured mobile app by utilising the clean and scalable architecture of Laravel with its ability to strengthen the backend for mobile apps. Get an end-to-end mobile solution that is built by skilled developers with several years of experience. We ensure that the mobile app developed by us will have advanced features that make you ahead of your competitors."
        },
        {
          "title": "Laravel API Development",
          "details": "With the help of the Laravel framework, the Nexalinx development team delivers API for clients’ services. For example, an API for a logistics platform allows carriers and cargo owners to make freight contracts faster. We have developer teams with mastery of Laravel API generator packages to create personalized API and RESTful solutions. With this, we blend third party integration to ensure the app reaches all platforms including mobile. Using Laravel’s flexible API authentication system, we develop customized APIs and integrate them into Laravel-based websites and apps. We create flexible yet stable RESTAPIs that can handle multiple types of calls in varied data formats with the correct implementation of hypermedia. We ensure faster communication with Laravel Database and 3rd party clients to reach your audience with RESTful APIs. We can connect your application seamlessly with third-party systems and tools through customized Lumen API development. We also perform unit tests, check API endpoints, and run automated feature tests in the browser for in-depth API testing."
        },
        {
          "title": "Laravel Package Development",
          "details": "Are you looking for Laravel developers with experience in Laravel package development who could rewrite an open-source library into a solid Laravel package? Do you want to install a Laravel package and make customizations to it? Our Laravel developers understand the need to have a feature-rich web app and website for your business. We’re fluent with the entire ecosystem of Laravel Packages. Packages are the primary way of adding functionality and unique features to your Laravel web application by developing add-ons. From stand-alone to dedicated packages, we provide all types of Laravel package development services with a seamless interface. Our developers can either utilize the Packalyst directory or build custom packages. We explore special Laravel packages development needs such as e-commerce, payment integration, catalogue development, delivery process, and more."
        },
        {
          "title": "Laravel Integration",
          "details": "Do you want to build a website in PHP and use multiple third-party APIs in it, like Laravel payment gateway integration, cart management, shipment management, maps, social logins, or customer support APIs? Are you looking for a senior-level architect who can lead a team of PHP developers to build any set of HTTP based APIs that you need? They could be external-facing APIs, like Paypal, a set of APIs to support a javascript web application running in a browser, or a set of APIs supporting a native mobile app. We can seamlessly integrate your existing application with 3rd party software and apps to exchange the data securely and safely. Whether you want to integrate 3rd party applications with your website or get your application integrated with legacy systems, our Laravel developers can do it in a timely manner and at an affordable price. We can also develop software libraries to quickly integrate the service into your app."
        },
        {
          "title": "Laravel Support And Maintenance",
          "details": "We also provide post-development maintenance and support for the Laravel application or website you developed with us, at a pocket-friendly price. To prevent problems, our team will closely be analyzing the post-testing concerns like load time, graphics clarity, load speed, navigation functionality, etc. We also cover our support to the existing websites that need attention. Our Laravel support & maintenance services aim at solving your queries, backing up data, updating themes & plugins, providing security updates, monitoring & fixing technical problems, upgrading features, enhancing your application UI/UX, and boosting backend performance. Our dedicated Laravel developers will make sure that your website users get a seamless experience at any given time. The important factors of Laravel web development services like authentication, routing, sessions, and caching are best managed by us. We can also build new features according to progressive needs or offer extended customization to modify the built-in features and functionalities of templates designed for a particular business use case."
        },
        {
          "title": "Laravel Migration",
          "details": "If your existing application was not built using the PHP framework, our team can switch your app from the existing framework to Laravel, with complete data migration. Through our framework migration services, our clients get seamless migrations from varied frameworks to Laravel powered interfaces. We can rewrite the structure of your legacy application using Laravel's helpers and syntax, including blade templating. We can port your existing code to the Laravel 5 Framework with Eloquent ORM. We can help you migrate and refurbish your existing PHP/MySQL website to a Laravel-based website. We can add significant features with Bundles, simplify complicated coding by employing BFF with Backbone, easily migrate from Vanilla PHP or other frameworks to Laravel without disrupting your business processes and losing relevant data. Through our Laravel updation solutions, you can seamlessly migrate from an earlier Laravel platform to the latest version (9) of Laravel."
        }
      ]
    }
  };

  const renderFeature = (data, index) => {
    const Icon = iconList2.findIndex(element => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (

      <Col lg={6} sm={12} key={index}>
        <div className="technology-list"> <span>
          <Icon2 />
        </span>
          <label> {data.title} </label>
        </div>
      </Col>
    );
  };

  const renderlist = (data, index) => {
    const Icon = iconList2.findIndex(element => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (

      <li key={index}> <span>
        <Icon2 />
      </span>
        <p>{data.title}</p>
      </li>
    );
  };

  const renderServiceLeft = (data, index) => {
    const Icon = iconList2.findIndex(element => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (

      <div className="company-slide mission-vission" key={index}>
        <Row>
          <Col md={3}>
            <div className="slider-icon icon-all" data-aos="flip-left">
              <Icon2 />
            </div>
          </Col>
          <Col md={9} className="ps-0">
            <h5 className='p-0'>{data.title}</h5>
            <p className='mb-0'> {data.details} </p>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white"><strong>{serviceData.title}</strong></h1>
                <p className="text-white p20 mb-4">{serviceData.subTitle}</p>
              </div>
              <Row>
                {serviceData.feature.map(data => renderFeature(data))}
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section>
      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <img className="width-full mt-5" src={indcom} alt="indcom" />
            </Col>
            <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
              <h2>{serviceData?.heading_one?.title}</h2>
              <p>{serviceData?.heading_one?.details}</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2>{serviceData.service_items.title}</h2>
              <p> {serviceData.service_items.subTitle} </p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaLaptop />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className='p-0'>{serviceData.service_items?.items[0].title}</h5>
                    <p className='mb-0'> {serviceData.service_items?.items[0].details} </p>
                  </Col>
                </Row>
              </div>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className='p-0'>{serviceData.service_items?.items[1].title}</h5>
                    <p className='mb-0'> {serviceData.service_items?.items[1].details} </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaMobileAlt />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className='img-width' src={serviceall} alt="serviceall" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaServer />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className='p-0'>{serviceData.service_items?.items[2].title}</h5>
                    <p className='mb-0'> {serviceData.service_items?.items[2].details} </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className='p-0'>{serviceData.service_items?.items[3].title}</h5>
                    <p className='mb-0'> {serviceData.service_items?.items[3].details} </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaFileCode />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaSearchengin />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className='p-0'>{serviceData.service_items?.items[4].title}</h5>
                    <p className='mb-0'> {serviceData.service_items?.items[4].details} </p>
                  </Col>
                </Row>
              </div>
            </Col>

            {serviceData.service_items?.items[5] &&
              <Col md={6}>
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className='p-0'>{serviceData.service_items?.items[5]?.title}</h5>
                      <p className='mb-0'> {serviceData.service_items?.items[5]?.details} </p>
                    </Col>
                    <Col md={3}>
                      <div className="slider-icon icon-all" data-aos="flip-right">
                        <FaSolarPanel />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            }
            {serviceData.service_items?.items[6] &&
              <Col md={6} >
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className='p-0'>{serviceData.service_items?.items[6]?.title}</h5>
                      <p className='mb-0'> {serviceData.service_items?.items[6]?.details} </p>
                    </Col>
                    <Col md={3}>
                      <div className="slider-icon icon-all" data-aos="flip-right">
                        <FaScrewdriver />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            }
            {serviceData.service_items?.items[7] &&
              <Col md={6}>
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className='p-0'>{serviceData.service_items?.items[7]?.title}</h5>
                      <p className='mb-0'> {serviceData.service_items?.items[7]?.details} </p>
                    </Col>
                    <Col md={3}>
                      <div className="slider-icon icon-all" data-aos="flip-right">
                        <FaSoundcloud />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            }

          </Row>
        </Container>
      </section>
      <RecentWork />
      <IndustryExperience />
    </Common>
  )
};

export default Laravel;