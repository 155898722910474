import React from 'react';
import pay from '../../assets/images/pay.svg';
import angular from '../../assets/images/ani.svg';
 import homepage from '../../assets/images/homepage.png';
import classifiedad from '../../assets/images/classified-ad-project.jpg';
import vexanimeproject from '../../assets/images/vexanimeproject.jpg';
import reacjs from '../../assets/images/reacjs.svg';
import adminpanelreact from '../../assets/images/adminpanelreact.jpg';
import socialuidproject from '../../assets/images/social-uid-project.jpg';
import zoomclone from '../../assets/images/zoomclone.jpg';
import ScreenwritingProject from '../../assets/images/ScreenwritingProject.jpg';
import radioproject from '../../assets/images/radioproject.jpg';
import veni from '../../assets/images/veni.png';
import screenwritingproject2 from '../../assets/images/screenwritingproject2.jpg';
import Core_Logo from '../../assets/images/NET_Core_Logo.svg';
import mongo from '../../assets/images/mongo.svg';
import js from '../../assets/images/js.png';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
// import Button from 'react-bootstrap/Button';
// import { NavLink } from 'react-router-dom';
// import { FaArrowRight } from 'react-icons/fa';


const Webapp = () => {
    return (
<div>
  <Row className="mt-5 pt-5">
    <Col md={7} className="mt-3">
    <h2 className="mt-5">Survey Project</h2>
    <p className="pe-5"> The survey project involved creating a user-friendly survey platform that collected and analyzed customer feedback from different websites. The project team developed a web crawler that gathered customer reviews and ratings and used sentiment analysis to categorize the feedback as positive, negative, or neutral. The platform also included a user interface that allowed users to search for specific products or services and view the associated feedback in easy-to-understand graphs and charts. The team conducted extensive testing to ensure the accuracy and reliability of the platform, including assessing the quality of the data collected and testing the functionality and performance of the platform. The project aimed to provide users with valuable insights into customer sentiment towards different products and services, using sentiment analysis and easy-to-understand visualizations to simplify the process of analyzing and comparing feedback from multiple sources. </p>
    <h4 className="mt-4 text-black mb-3"><strong>Technologies Used</strong></h4>
    <div className="blog-tech"> <span><img src={reacjs} alt="reacjs" /></span>
      <p>Reactjs</p>
    </div>
    <div className="blog-tech"> <span><img src={Core_Logo} alt="Core_Logo" /></span>
      <p>.Net Core API</p>
    </div>
    <div className="blog-tech"> <span><img className='mg-icon' src={mongo} alt="mongo" /></span>
      <p>Mango DB</p>
    </div>
    <div className="clearfix"></div>
    {/*
    <Col md={12} className="text-start mt-4">
    <NavLink exact to="/blog-details">
      <Button variant="primary btn-custom mt-3 btn-big w260 w190">View Project
      <FaArrowRight className="ms-5" />
      </Button>
    </NavLink>
    </Col>
    */}
    </Col>
    <Col md={5} className="mt-4 mb-4 blog-all">
    <div className="company-slide">
      <div className="slider-icon2"><img src={ScreenwritingProject}  className="portimg" alt="ScreenwritingProject" /> </div>
    </div>
    </Col>
  </Row>
  <Row className="mt-5 pt-5">
    <Col md={5} className="mt-4 mb-4 blog-all">
    <div className="company-slide">
      <div className="slider-icon2"><img src={vexanimeproject} className="portimg" alt="vexanimeproject" /> </div>
    </div>
    </Col>
    <Col md={7} className="mt-3 ps-5">
    <h2 className="mt-5">Vexanime Project</h2>
    <p className="pe-5"> The project involved creating a streaming platform called Vex Anime for anime episodes. The aim of the project was to provide anime enthusiasts with a convenient and easy-to-use platform to watch their favorite anime series online. The team focused on developing an intuitive user interface with various features, including a search bar, a list of recommended anime series, and a section for new releases. Additionally, the team implemented a subscription-based model for premium content, which gave users exclusive access to certain anime series and additional features. The team conducted extensive testing and collaborated with anime distributors to ensure that the website's functionality and content were legally obtained and in compliance with copyright laws. The project resulted in a successful and valuable tool for anime lovers, providing them with a seamless streaming experience and access to a vast selection of anime series. </p>
    <h4 className="mt-4 text-black mb-3"><strong>Technologies Used</strong></h4>
    <div className="blog-tech"> <span><img src={angular} alt="angular" /></span>
      <p>Angularjs</p>
    </div>
    <div className="blog-tech"> <span><img src={pay} alt="pay" /></span>
      <p>ASP.NET MVC</p>
    </div>
    <div className="clearfix"></div>
    </Col>
  </Row>

  <Row className="mt-5 pt-5">
    <Col md={7} className="mt-3">
    <h2 className="mt-5">Social-UID Project</h2>
    <p className="pe-5"> 
    The project was a unique and innovative offering that brought together social media, news, and OTT platforms in one place. With this approach, users could access multiple platforms and their respective content without having to switch between different applications. The social media platform enabled users to connect with their friends and family, share their thoughts and keep up with the latest trends. The news channel platform provided a comprehensive range of news, while the dedicated OTT platform offered an extensive library of movies, TV shows, documentaries, and other content. This integrated approach simplified the user experience, making it easier for them to discover new content, engage with others, and stay informed. The project was a significant step toward creating a more connected and efficient media landscape.

    </p>
    <h4 className="mt-4 text-black mb-3"><strong>Technologies Used</strong></h4>
    <div className="blog-tech"> <span><img src={angular} alt="angular" /></span>
      <p>Angularjs</p>
    </div>
    <div className="blog-tech"> <span><img src={pay} alt="pay" /></span>
      <p>Django</p>
    </div>
    <div className="clearfix"></div>
    {/*
    <Col md={12} className="text-start mt-4">
    <NavLink exact to="/blog-details">
      <Button variant="primary btn-custom mt-3 btn-big w260 w190">View Project
      <FaArrowRight className="ms-5" />
      </Button>
    </NavLink>
    </Col>
    */}
    </Col>
    <Col md={5} className="mt-4 mb-4 blog-all">
    <div className="company-slide">
      <div className="slider-icon2"><img src={socialuidproject}  className="portimg" alt="social-uid-project" /> </div>
    </div>
    </Col>
  </Row>
  <Row className="mt-5 pt-5">
    <Col md={5} className="mt-4 mb-4 blog-all">
    <div className="company-slide">
      <div className="slider-icon2"><img src={classifiedad} className="portimg" alt="classifiedad" /> </div>
    </div>
    </Col>
    <Col md={7} className="mt-3 ps-5">
    <h2 className="mt-5">Classified AD Project</h2>
    <p className="pe-5"> Genuine information is still not readily accessible to a less accomplished public. While talent could be found in hidden deep-pocketed regions, the lack of information about such talent becomes a bottleneck in their development. We at CROSSLINKER are pledged to bring deeply hidden information to hard-to-reach people and places. Consider CROSSLINKER as a philanthropist company that would like to help those who want to make a positive contribution and impact on human society. The concept of CROSSLINKER was designed by a high school student with the vision to make this data-driving world safer & secure that offering life-transforming information accessible to every part of the globe. Too much information creates confusion and therefore having precise information will be critical
</p>
    <h4 className="mt-4 text-black mb-3"><strong>Technologies Used</strong></h4>
    <div className="blog-tech"> <span><img src={reacjs} alt="reacjs" /></span>
      <p>Reactjs</p>
    </div>
    <div className="blog-tech"> <span><img src={pay} alt="pay" /></span>
      <p>Django</p>
    </div>
    <div className="clearfix"></div>
    </Col>
  </Row>

  <Row className="mt-5 pt-5">
    <Col md={7} className="mt-3">
    <h2 className="mt-5">Tour Plan</h2>
    <p className="pe-5"> The project involved creating a website that simplified trip planning for customers. The website provided information on transportation options and travel times to reach destinations, along with details on popular attractions and accommodations. The team integrated various transportation systems' data and tested the website for accuracy and reliability. The goal was to make trip planning easy and stress-free for customers.
 </p>
    <h4 className="mt-4 text-black mb-3"><strong>Technologies Used</strong></h4>
    <div className="blog-tech"> <span><img src={reacjs} alt="reacjs" /></span>
      <p>Reactjs</p>
    </div>
    <div className="blog-tech"> <span><img src={pay} alt="pay" /></span>
      <p>Django</p>
    </div>
 
    <div className="clearfix"></div>
    {/*
    <Col md={12} className="text-start mt-4">
    <NavLink exact to="/blog-details">
      <Button variant="primary btn-custom mt-3 btn-big w260 w190">View Project
      <FaArrowRight className="ms-5" />
      </Button>
    </NavLink>
    </Col>
    */}
    </Col>
    <Col md={5} className="mt-4 mb-4 blog-all">
    <div className="company-slide">
      <div className="slider-icon2"><img src={homepage}  className="portimg" alt="homepage" /> </div>
    </div>
    </Col>
  </Row>

  <Row className="mt-5 pt-5">
    <Col md={5} className="mt-4 mb-4 blog-all">
    <div className="company-slide">
      <div className="slider-icon2"><img src={zoomclone} className="portimg" alt="zoomclone" /> </div>
    </div>
    </Col>
    <Col md={7} className="mt-3 ps-5">
    <h2 className="mt-5">Zoom Clone Web design
</h2>
    <p className="pe-5"> The project was a React-based website that cloned all the features of Zoom.com, including video conferencing, screen sharing, recording, and chat. It provided a reliable video conferencing experience with high-quality audio and video, as well as collaboration tools like screen sharing and chat. The platform was a comprehensive solution for businesses and individuals looking for a video conferencing tool similar to Zoom.com.
 </p>
    <h4 className="mt-4 text-black mb-3"><strong>Technologies Used</strong></h4>
    <div className="blog-tech"> <span><img src={reacjs} alt="reacjs" /></span>
      <p>Reactjs</p>
    </div>
    <div className="blog-tech"> <span><img src={pay} alt="pay" /></span>
      <p>Django</p>
    </div>
    <div className="clearfix"></div>
    </Col>
  </Row>
  <Row className="mt-5 pt-5">
    <Col md={7} className="mt-3">
    <h2 className="mt-5">Admin Panel Project</h2>
    <p className="pe-5"> 
    The project was a store management system that I developed using React. It was designed to help salespersons track customer spending, phone numbers, and activities, and included a campaign feature for creating offers and promotions. My focus was on creating an intuitive and easy-to-use front end for the client.
The store management system provided a powerful tool for businesses to track customer behavior and implement targeted marketing campaigns to increase sales. The project's robust features and user-friendly interface made it a valuable asset for salespersons looking to optimize their sales strategy and increase revenue.

 </p>
    <h4 className="mt-4 text-black mb-3"><strong>Technologies Used</strong></h4>
    <div className="blog-tech"> <span><img src={reacjs} alt="reacjs" /></span>
      <p>Reactjs</p>
    </div>
    <div className="blog-tech"> <span><img src={pay} alt="pay" /></span>
      <p>Django</p>
    </div>
 
    <div className="clearfix"></div>
    {/*
    <Col md={12} className="text-start mt-4">
    <NavLink exact to="/blog-details">
      <Button variant="primary btn-custom mt-3 btn-big w260 w190">View Project
      <FaArrowRight className="ms-5" />
      </Button>
    </NavLink>
    </Col>
    */}
    </Col>
    <Col md={5} className="mt-4 mb-4 blog-all">
    <div className="company-slide">
      <div className="slider-icon2"><img src={adminpanelreact}  className="portimg" alt="adminpanelreact" /> </div>
    </div>
    </Col>
  </Row>

  <Row className="mt-5 pt-5">
    <Col md={5} className="mt-4 mb-4 blog-all">
    <div className="company-slide">
      <div className="slider-icon2"><img src={radioproject} className="portimg" alt="radioproject" /> </div>
    </div>
    </Col>
    <Col md={7} className="mt-3 ps-5">
    <h2 className="mt-5">Radio Project
</h2>
    <p className="pe-5">The project Radio Go was built using React and Python, offering users access to over 2000 Greek radio stations featuring various genres of music, talk shows, and sports coverage. Its use of React allowed for a fast and responsive user experience, while Python provided a reliable infrastructure for managing and organizing the collection of stations. The platform's search and filtering features make it easy for users to find the perfect station for their listening preferences.

 </p>
    <h4 className="mt-4 text-black mb-3"><strong>Technologies Used</strong></h4>
    <div className="blog-tech"> <span><img src={reacjs} alt="reacjs" /></span>
      <p>Reactjs</p>
    </div>
    <div className="blog-tech"> <span><img src={pay} alt="pay" /></span>
      <p>Django</p>
    </div>
    <div className="clearfix"></div>
    </Col>
  </Row>
  <Row className="mt-5 pt-5">
    <Col md={7} className="mt-3">
    <h2 className="mt-5">Screenwriting Project</h2>
    <p className="pe-5"> 
    The project was a web-based application that I built using vanilla JavaScript. It allowed users to create scripts for dramas, movies, or other productions. It featured customizable templates, automatic formatting, and a built-in script analysis tool for refining and perfecting scripts. Users could create character profiles, manage scenes, and collaborate with a team in real time. The project provided an easy-to-use platform for writers of all skill levels, with features that streamlined the writing process and ensured accuracy.

 </p>
    <h4 className="mt-4 text-black mb-3"><strong>Technologies Used</strong></h4>
    <div className="blog-tech"> <span><img src={js} alt="js" /></span>
      <p>Javascript</p>
    </div>
    <div className="blog-tech"> <span><img src={veni} alt="veni" /></span>
      <p>Vanilla </p>
    </div>
 
    <div className="clearfix"></div>
    {/*
    <Col md={12} className="text-start mt-4">
    <NavLink exact to="/blog-details">
      <Button variant="primary btn-custom mt-3 btn-big w260 w190">View Project
      <FaArrowRight className="ms-5" />
      </Button>
    </NavLink>
    </Col>
    */}
    </Col>
    <Col md={5} className="mt-4 mb-4 blog-all">
    <div className="company-slide">
      <div className="slider-icon2"><img src={screenwritingproject2}  className="portimg" alt="screenwritingproject2" /> </div>
    </div>
    </Col>
  </Row>

</div>
);
};

export default Webapp;