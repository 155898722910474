import React from 'react';

import Common from "../../layouts/Common";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { FaLockOpen } from 'react-icons/fa';
import { FaGlobe } from 'react-icons/fa';
import { FaWaveSquare } from 'react-icons/fa';
import { FaFileAlt } from 'react-icons/fa';
import { FaFileInvoice } from 'react-icons/fa';
import { FaClock } from 'react-icons/fa';
import { FaSlideshare } from 'react-icons/fa';
import { FaMobileAlt } from 'react-icons/fa';
import { FaUserAlt } from 'react-icons/fa';
import { FaCommentAlt } from 'react-icons/fa';
import { FaCommentDots } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FaArrowRight } from 'react-icons/fa';

import { FaStarOfLife } from 'react-icons/fa';
import { FaScribd } from 'react-icons/fa';
import { FaFileCode } from 'react-icons/fa';
import { FaSearchengin } from 'react-icons/fa';
import { FaSolarPanel } from 'react-icons/fa';
import { FaScrewdriver } from 'react-icons/fa';

import Container from 'react-bootstrap/Container';
import worka from '../../assets/images/worka.png';
import workb from '../../assets/images/workb.png';
import workc from '../../assets/images/workc.png';
import workd from '../../assets/images/workd.png';
import worke from '../../assets/images/worke.png';
import workf from '../../assets/images/workf.png';
import indu1 from '../../assets/images/indu1.svg';
import indu2 from '../../assets/images/indu2.svg';
import indu3 from '../../assets/images/indu3.svg';
import indu4 from '../../assets/images/indu4.svg';
import indu5 from '../../assets/images/indu5.svg';
import indu6 from '../../assets/images/indu6.svg';
import softwareqaservices from '../../assets/images/software-qa-services.svg';





import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ContactForm from '../../components/ContactForm';
const SoftwareQaTestingServices = () => {
    const options2 = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        autoplay: true,
        // navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items:1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
            },
            1000: {
                items:3,
            }
        },
      };
        return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
            <div className="slider-banner slide-arrow">
              <h1 className="text-white"><strong>Software Testing And Quality Assurance Company in Bangladesh </strong></h1>
              <p className="text-white p20 mb-4">
              We will offer quality-oriented QA and software testing services in Bangladesh to ensure that every aspect of software meets your standards and business demands. We provide end-to-end software solutions by implementing the best and latest testing methodologies. We are experts in improving software quality by mitigating technical and business risks, and thus escalating your ROI. We are specialized in software testing services in Bangladesh for any type of application whether it is mobile, desktop, or next-gen-based applications and can deliver bug-free and high-performance digital solutions according to your specific requirements.
                </p>
            </div>
            <Row>
              <Col lg={6} sm={12}>
              <div className="technology-list"> <span>
                <FaLockOpen/>
                </span>
                <label> Security & IP Protection </label>
              </div>
              </Col>
              <Col lg={6} sm={12}>
              <div className="technology-list"> <span>
                <FaGlobe/>
                </span>
                <label> 5 Years Experience </label>
              </div>
              </Col>
              <Col lg={6} sm={12}>
              <div className="technology-list"> <span>
                <FaWaveSquare/>
                </span>
                <label> Flexible Engagement Models </label>
              </div>
              </Col>
              <Col lg={6} sm={12}>
              <div className="technology-list"> <span>
                <FaFileAlt/>
                </span>
                <label> Strict NDA Terms </label>
              </div>
              </Col>
              <Col lg={6} sm={12}>
              <div className="technology-list"> <span>
                <FaFileInvoice/>
                </span>
                <label> No Hidden Costs </label>
              </div>
              </Col>
              <Col lg={6} sm={12}>
              <div className="technology-list"> <span>
                <FaClock/>
                </span>
                <label> On-Time Project Delivery </label>
              </div>
              </Col>
            </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section>
      <section className="all-company-feature mt-5 pt-5">
        <Container>

        <Row>
            <Col md={12} className="mt-4 mb-4" data-aos="fade-left">
                <h2>Our Software Maintenance & Support Services</h2>
                <p>
                While enhancing the functionality, we will ensure that your applications run with complete efficiency as per your requirements. Our engineers are able to elevate your organisation’s growth by providing high-quality applications that can successfully convert your business statistics. We will help you modernise your system with our reliable support and software product maintenance services that can assure your success in every step.


                </p>
            </Col>
         
            </Row>
       
        </Container>
      </section>

      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
       
          <Row>
            <Col md={6}>
            <div className="company-slide mission-vission mission-vission2">
              <Row>
                <Col md={3}>
                <div className="slider-icon icon-all" data-aos="flip-left">
                  <FaStarOfLife/>
                </div>
                </Col>
                <Col md={9} className="ps-0">
                <h5 className='p-0'>Functional Testing</h5>
                <p className='mb-0'> 
                We are skilled in validating software to check whether it meets different functional specifications and requirements to deliver high-quality software solutions. We efficiently test each functionality by providing valid inputs and inspecting output to examine whether it is relevant to the required specification. By leveraging tools like Postman, TestComplete, Chrome Development Tools, Avo Assure, Jira, Trello, Redmine, etc., we perform testing for the seamless functioning of applications. We will deliver error-free and feature-rich applications that meet your business demands.
                </p>
                </Col>
              </Row>
            </div>
  

            <div className="company-slide mission-vission mission-vission2">
              <Row>
                <Col md={9} className="pe-0 text-end">
                <h5 className='p-0'>Load & Performance Testing</h5>
                <p className='mb-0'> 
                We are experts in testing the behaviour of software solutions by evaluating their speed and reliability under varying loads. This will eliminate bottlenecks in the development phase. We are capable of determining the performance of applications by checking their ability to withstand the load, when it is accessed by numerous users at a time. By leveraging tools like LoadRunner, NeoLoad, JMeter, Chrome Development Tools, etc., we are able to rectify performance risks and deliver reliable and robust software solutions as per your requirements.
                 </p>
                </Col>
                <Col md={3}>
                <div className="slider-icon icon-all" data-aos="flip-right">
                  <FaSlideshare/>
                </div>
                </Col>
              </Row>

              
            </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
            <img className='img-width' src={softwareqaservices} alt="serviceall" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
            <div className="company-slide mission-vission mission-vission2 pb-5">
              <Row>
                <Col md={3}>
                <div className="slider-icon icon-all" data-aos="flip-left">
                  <FaScribd/>
                </div>
                </Col>
                <Col md={9} className="ps-0">
                <h5 className='p-0'>Security Testing</h5>
                <p className='mb-0 pb-3'> 
                We are efficient in mitigating the vulnerabilities and security risks in applications by using technology-forward testing tools so we can provide hacker-proof software solutions. By leveraging tools like ZAP, Wfuzz, Wapiti, etc., we will execute a testing process which aids developers to build applications that provide a higher level of protection to your sensitive data. As a top cybersecurity company,we will provide end-to-end applications that are safe from cyber security breaches and are according to your business requirements.

. </p>
                </Col>
              </Row>
            </div>
            </Col>
            <Col md={6}>
            <div className="company-slide mission-vission mission-vission2 pb-5">
              <Row>
                <Col md={9} className="pe-0 text-end">
                <h5 className='p-0'>Mobile App Testing
</h5>
                <p className='mb-0'>
                As a leading software testing company in Bangladesh, we will provide manual testing as well as automated testing approaches for mobile apps to help developers deliver reliable and scalable applications as per your requirements. We execute testing for UI/UX, frontend, and backend efficiently to provide secure mobile applications without bugs and crashes. By using tools like Test Complete, Test IO, Appium, Espresso, etc., we will test all aspects of your apps whether it is native, hybrid, or web mobile apps to ensure their seamless functioning in accordance with your business needs.

</p>
                </Col>
                <Col md={3}>
                <div className="slider-icon icon-all" data-aos="flip-right">
                  <FaFileCode/>
                </div>
                </Col>
              </Row>
            </div>
            </Col>
       
          </Row>


          <Row>
            <Col md={6}>
            <div className="company-slide mission-vission mission-vission2 pb-5">
              <Row>
                <Col md={3}>
                <div className="slider-icon icon-all" data-aos="flip-left">
                  <FaSearchengin/>
                </div>
                </Col>
                <Col md={9} className="ps-0">
                <h5 className='p-0'>Usability Testing</h5>
                <p className='mb-0 pb-4'> 
                We perform testing in User Interface and User Experience designs to ensure that your users get a smooth experience while using your applications. By leveraging tools like Maze, Lookback, Optimal Workshop, etc., we execute testing and offer applications with an intuitive user experience. We are efficient in identifying all usability issues and will help developers to deliver user-friendly and feature-rich applications that fit your specific requirements.

. </p>
                </Col>
              </Row>
            </div>
            </Col>
            <Col md={6}>
            <div className="company-slide mission-vission mission-vission2 pb-5">
              <Row>
                <Col md={9} className="pe-0 text-end">
                <h5 className='p-0'>Compatibility Testing

</h5>
                <p className='mb-0'>
                We are experts in testing software applications and checking whether they can function efficiently in different web browsers, operating systems, hardware platforms, networks, mobile devices, etc. By using tools like LambdaTest, CrossBrowserTesting, Functionalize, etc., we check the compatibility of applications to ensure a good customer experience. We will effectively identify all compatibility issues and deliver reliable and end-to-end applications that meet your business requirements.

</p>
                </Col>
                <Col md={3}>
                <div className="slider-icon icon-all" data-aos="flip-right">
                  <FaSolarPanel/>
                </div>
                </Col>
              </Row>
            </div>
            </Col>
       
          </Row>

          <Row>
            <Col md={6}>
            <div className="company-slide mission-vission mission-vission2 pb-3">
              <Row>
                <Col md={3}>
                <div className="slider-icon icon-all" data-aos="flip-left">
                  <FaScrewdriver/>
                </div>
                </Col>
                <Col md={9} className="ps-0">
                <h5 className='p-0'>Test Automation</h5>
                <p className='mb-0 pb-4'> 
                Our test automation services focus on achieving quality at maximum speed and minimizing risk in software applications. By leveraging tools like Selenium, LambdaTest, Win Runner, TestComplete, Cucumber, etc., we perform automation testing to add more value for customer satisfaction. We will provide end-to-end test automation services to deliver reliable and high-performance applications as per your business demands.

. </p>
                </Col>
              </Row>
            </div>
            </Col>
            <Col md={6}>
            <div className="company-slide mission-vission mission-vission2 pb-5">
              <Row>
                <Col md={9} className="pe-0 text-end">
                <h5 className='p-0'>Migration And Upgradation Services

</h5>
                <p className='mb-0 pb-5 mb-5'>
                We apply appropriate testing methodologies to the migrated or upgraded applications for their seamless functioning. By leveraging the latest tools, we perform testing processes, and provide scalable, reliable, and feature-rich applications.

</p>
                </Col>
                <Col md={3}>
                <div className="slider-icon icon-all" data-aos="flip-right">
                  <FaSlideshare/>
                </div>
                </Col>
              </Row>
            </div>
            </Col>
       
          </Row>
        </Container>
      </section>


      <Container>
      <Row>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
            <img className="width-full mt-5" src={softwareqaservices} alt="indcom" />
            </Col>
            <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
            <h2 className="mt-5 pt-4">Our Expertise In<br/>
QA & Software Testing Services</h2>
            <p>
            We are experts in improving the quality of software by rectifying risks in it and aiding developers to deliver reliable and high-quality software solutions. We perform quality assurance and testing processes and inspect applications efficiently, whether they perform well while considering different factors like functionality, security, performance, usability, and many others. We understand the market and the customers thoroughly, and by utilizing state-of-the-art tools and techniques, we will execute the testing process to provide high-performance applications as per your needs.
                  </p>
        
          
            </Col>
          </Row>



          </Container>


      

      <section className="all-company-feature bg-deep-gray mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
            <h2>Our Recent Works</h2>
            </Col>
          </Row>
          <Row>
          <OwlCarousel className='owl-theme slider-custom' {...options2} loop margin={20} nav>
      
      <div class='item'>
      <div className='work-block-slide'> <img className='img-width-full' src={worka} alt="worka" /> </div>
      </div>
      <div class='item'>
      <div className='work-block-slide'> <img className='img-width-full' src={workb} alt="workb" /> </div>
      </div>
      <div class='item'>
      <div className='work-block-slide'> <img className='img-width-full' src={workc} alt="workc" /> </div>
      </div>
      <div class='item'>
      <div className='work-block-slide'> <img className='img-width-full' src={workd} alt="workd" /> </div>
      </div>
    
      <div class='item'>
      <div className='work-block-slide'> <img className='img-width-full' src={worke} alt="worke" /> </div>
      </div>
      <div class='item'>
      <div className='work-block-slide'> <img className='img-width-full' src={workf} alt="workf" /> </div>
      </div>
      
    </OwlCarousel>
    
          </Row>
        </Container>
      </section>
      <section className="all-company-feature">
        <Container>
          <Row>
            <Col md={12} className="text-center mt-5 pt-5">
            <h2>Our Industry Experience</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu1} alt="indu1" /> </div>
              <h5 className="text-center mt-5">Ecommerce</h5>
              <p> The e-commerce solutions offered at Nexalinx are on-the-go and assured to bring benefits with increased ROI and overall productivity. We provide custom e-commerce development services and develop web apps for any kind of business across various industries and enterprises ranging from B2B and B2C trading networks to small, middle-sized, and large businesses. These custom solutions meet the user’s requirements by applying specific areas of specialization. </p>
            </div>
            </Col>
            <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu2} alt="indu2" /> </div>
              <h5 className="text-center mt-5">Healthcare</h5>
              <p> At Nexalinx, we help you develop healthcare software applications that automate processes such as patient management, lab management, test result analysis, and data collection. For the medical technology companies, from consulting to maintenance and support we always lent our supporting hands to tackle your technological dilemmas. </p>
            </div>
            </Col>
            <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu3} alt="indu3" /> </div>
              <h5 className="text-center mt-5">Logistics</h5>
              <p>The experts at Nexalinx help you build innovative and impressive tech-enabled logistics software applications to streamline the supply chain and provide flawless customer support. The warehouse management software also makes sure to enhance warehouse efficiency. The expert logistics and transportation management software offered by Nexalinx thereby help you do your tasks more effectively.</p>
            </div>
            </Col>
            <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu4} alt="indu4" /> </div>
              <h5 className="text-center mt-5">Education</h5>
              <p> In the education field, Nexalinx is engaged in integrated learning system software development, virtual learning management system, LMS software development, and even personalized e-learning software systems. Interactive, convenient, and cost-effective solutions are what we offer at Nexalinx. </p>
            </div>
            </Col>
            <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu5} alt="indu5" /> </div>
              <h5 className="text-center mt-5">Banking</h5>
              <p> From financial software development, custom banking software development, digital banking software services, and custom Fintech development, we at Nexalinx extend our software development experience in the industry of banking and finance as well. With custom features, and UI/UX designs we ensure the highest standards and security to the software developed. </p>
            </div>
            </Col>
            <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu6} alt="indu6" /> </div>
              <h5 className="text-center mt-5">Manufacturing</h5>
              <p> Implement manufacturing software for ensuring quality management and maintenance software including the application of intelligent products that help in accelerating the existing processes and bringing down operational costs. </p>
            </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Common>
    );
};

export default SoftwareQaTestingServices;