import React from 'react';

import Common from "../../layouts/Common";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { FaLockOpen } from 'react-icons/fa';
import { FaGlobe } from 'react-icons/fa';
import { FaWaveSquare } from 'react-icons/fa';
import { FaFileAlt } from 'react-icons/fa';
import { FaFileInvoice } from 'react-icons/fa';
import { FaClock } from 'react-icons/fa';
import { FaSlideshare } from 'react-icons/fa';
import { FaMobileAlt } from 'react-icons/fa';
import { FaUserAlt } from 'react-icons/fa';
import { FaCommentAlt } from 'react-icons/fa';
import { FaCommentDots } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FaArrowRight } from 'react-icons/fa';

import { FaStarOfLife } from 'react-icons/fa';
import { FaScribd } from 'react-icons/fa';
import { FaFileCode } from 'react-icons/fa';
import { FaSearchengin } from 'react-icons/fa';
import { FaSolarPanel } from 'react-icons/fa';
import { FaSlack } from 'react-icons/fa';

import Container from 'react-bootstrap/Container';
import worka from '../../assets/images/worka.png';
import workb from '../../assets/images/workb.png';
import workc from '../../assets/images/workc.png';
import workd from '../../assets/images/workd.png';
import worke from '../../assets/images/worke.png';
import workf from '../../assets/images/workf.png';
import indu1 from '../../assets/images/indu1.svg';
import indu2 from '../../assets/images/indu2.svg';
import indu3 from '../../assets/images/indu3.svg';
import indu4 from '../../assets/images/indu4.svg';
import indu5 from '../../assets/images/indu5.svg';
import indu6 from '../../assets/images/indu6.svg';
import hire from '../../assets/images/hire.svg';
import soft from '../../assets/images/soft.svg';
import trusted from '../../assets/images/trusted-cyber-security.svg';


import cybersecurity from '../../assets/images/cyber-security-services.svg';

import sof1 from '../../assets/images/sof1.svg';
import content from '../../assets/images/content.svg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ContactForm from '../../components/ContactForm';

const CyberSecurity = () => {
    const options2 = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        autoplay: true,
        // navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items:1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
            },
            1000: {
                items:3,
            }
        },
      };
        return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
            <div className="slider-banner slide-arrow">
              <h1 className="text-white"><strong>Cyber Security Company in Bangladesh </strong></h1>
              <p className="text-white p20 mb-4">
              NexaLinx offers unique data security services to protect your business, data, and assets with cutting-edge data protection technologies. We will assist you in maximising data control by utilising industry-leading loss prevention and encryption technology. As a top cyber security company in Bangladesh, we provide IT security solutions like database security, application security, cloud security, etc. to businesses of all sizes and types. We deliver each client with a continual and reliable barrier against any internet threat.
                </p>
            </div>
            <Row>
              <Col lg={6} sm={12}>
              <div className="technology-list"> <span>
                <FaLockOpen/>
                </span>
                <label> Security & IP Protection </label>
              </div>
              </Col>
              <Col lg={6} sm={12}>
              <div className="technology-list"> <span>
                <FaGlobe/>
                </span>
                <label> 5 Years Experience </label>
              </div>
              </Col>
              <Col lg={6} sm={12}>
              <div className="technology-list"> <span>
                <FaWaveSquare/>
                </span>
                <label> Flexible Engagement Models </label>
              </div>
              </Col>
              <Col lg={6} sm={12}>
              <div className="technology-list"> <span>
                <FaFileAlt/>
                </span>
                <label> Strict NDA Terms </label>
              </div>
              </Col>
              <Col lg={6} sm={12}>
              <div className="technology-list"> <span>
                <FaFileInvoice/>
                </span>
                <label> No Hidden Costs </label>
              </div>
              </Col>
              <Col lg={6} sm={12}>
              <div className="technology-list"> <span>
                <FaClock/>
                </span>
                <label> On-Time Project Delivery </label>
              </div>
              </Col>
            </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section>
      <section className="all-company-feature mt-5 pt-5">
        <Container>

        <Row>
            <Col md={12} className="mt-4 mb-4" data-aos="fade-left">
                <h2>Our Cyber Security Services</h2>
                <p>
                We will provide reliable cyber security services in Bangladesh to help you improve the cyber security of your applications by mitigating the risk. We efficiently identify security breaches and facilitate preventive action at the right time. Being a trusted cyber security company in Bangladesh, we will employ comprehensive security measures to develop standards-compliant solutions that fit your business demands. Our cyber security experts will follow a systematic method to detect and repair your most critical vulnerabilities and improve your brand image.


                </p>
            </Col>
         
            </Row>
       
        </Container>
      </section>

      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
       
          <Row>
            <Col md={6}>
            <div className="company-slide mission-vission mission-vission2">
              <Row>
                <Col md={3}>
                <div className="slider-icon icon-all" data-aos="flip-left">
                  <FaStarOfLife/>
                </div>
                </Col>
                <Col md={9} className="ps-0">
                <h5 className='p-0'>Application Security
</h5>
                <p className='mb-0'> 
                To prevent the theft or hijacking of data or code within the app, we deploy security measures at the application level. We provide security solutions to web, mobile, and desktop applications to aid companies in keeping their applications secure and safe. We employ security measures throughout the development lifecycle of an application to discover and correct flaws. We incorporate hardware, software, and procedures into the applications to identify or eliminate security vulnerabilities and unauthorized access. We are adept in application security testing to make sure that the new or updated version of the software applications is safe from security flaws.
                </p>
                </Col>
              </Row>
            </div>
  

            <div className="company-slide mission-vission mission-vission2">
              <Row>
                <Col md={9} className="pe-0 text-end">
                <h5 className='p-0'>Network Security
</h5>
                <p className='mb-0'> 
                Our experts can provide unique security solutions to safeguard your data and network from breaches and threats. We can help you protect your data in the network with proper authorization so that the users will get access only to the information within their authority. Our network security services involve access control, setting up antivirus software & firewalls, network analytics, VPN encryption, and more. We are capable of delivering dependable network performance to protect your sensitive information from cyber threats. We will offer robust network security and can help you keep your data safe from viruses, worms, trojans, spyware, adware, and other security attacks.


                 </p>
                </Col>
                <Col md={3}>
                <div className="slider-icon icon-all" data-aos="flip-right">
                  <FaSlideshare/>
                </div>
                </Col>
              </Row>

              
            </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
            <img className='img-width' src={cybersecurity} alt="cybersecurity" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
            <div className="company-slide mission-vission mission-vission2 pb-4">
              <Row>
                <Col md={3}>
                <div className="slider-icon icon-all" data-aos="flip-left">
                  <FaScribd/>
                </div>
                </Col>
                <Col md={9} className="ps-0">
                <h5 className='p-0'>Cloud Security
</h5>
                <p className='mb-0 pb-3'> 
We have the capacity to provide you with end-to-end cloud security services in Bangladesh to 
successfully manage your data and application across a hybrid cloud. By using state-of-the-art tools 
and techniques, we can implement a variety of solutions for maintaining application security, data protection,
 and infrastructure security. We can provide Cloud Security Monitoring solutions to safeguard your cloud-based
  operations and keep them protected from cyber-attacks. We will test and include the most up-to-date security 
  features into our systems and will ensure that our clients are in compliance with data protection and security 
  regulations such as PCI DSS, HIPAA, and GDPR.




. </p>
                </Col>
              </Row>
            </div>
            </Col>
            <Col md={6}>
            <div className="company-slide mission-vission mission-vission2 pb-5">
              <Row>
                <Col md={9} className="pe-0 text-end">
                <h5 className='p-0'>Threat Simulation

</h5>
                <p className='mb-0'>
                We can offer high-level threat simulation services that mimic a variety of threat scenarios to assess the cyber defence of your business processes in a secure and real-time environment. We perform threat simulation exercises to identify and prevent the techniques and procedures of various threat actors. We create an industry-specific scenario to simulate an actual breach and put your response strategy through its paces to evaluate your security controls. Our security team has the ability to counter sophisticated threats and improve the effectiveness of cybersecurity. We will update our simulations on a regular basis to reflect the most recent threats and will keep your organisation one step ahead of the attacker.
</p>
                </Col>
                <Col md={3}>
                <div className="slider-icon icon-all" data-aos="flip-right">
                  <FaFileCode/>
                </div>
                </Col>
              </Row>
            </div>
            </Col>
       
          </Row>


          <Row>
            <Col md={6}>
            <div className="company-slide mission-vission mission-vission2">
              <Row>
                <Col md={3}>
                <div className="slider-icon icon-all" data-aos="flip-left">
                  <FaSearchengin/>
                </div>
                </Col>
                <Col md={9} className="ps-0">
                <h5 className='p-0'>Compliance
</h5>
                <p className='mb-0 pb-4'> 
                We will strictly follow compliance standards and requirements to safeguard sensitive customer & employee information, trade secrets, software code, and other information of your company. We can implement robust cyber security compliance measures to protect customers’ critical data and build customer loyalty that helps you maintain the company’s reputation. We will execute a risk analysis process on your information asset, data, and networks so that we can set the controls like password policies, encryption, firewalls, insurance, etc. to reduce the vulnerabilities. We will provide compliance cyber security services in Bangladesh that add value to your organisation by ensuring that your business process meets required regulatory standards. We integrate security compliance standards like PCI DSS, HIPAA, SEBI, GDPR etc. to mitigate data breaches and reduce operational inefficiencies.



. </p>
                </Col>
              </Row>
            </div>
            </Col>
      
          </Row>

        
        </Container>
      </section>
<div className="clearfix"></div>



<Container>
      <Row>
            <Col md={6} className="mt-4 pt-5 mb-4" data-aos="fade-left">
            <img className="width-full mt-5" src={trusted} alt="trusted" />
            </Col>
            <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
            <h2 className="mt-5">What Makes Nexalinx Your Trusted Partner
In Cyber Security Services?</h2>
            <p>
            Our experts have great domain knowledge and proficiency in cyber security technologies, allowing them to provide clients with supreme quality services. Renowned as one of the best cyber security companies in Bangladesh, we utilize advanced tools and techniques to provide reliable and secure applications that meet the clients’ specific requirements. We can help you safeguard your company's most critical data, applications, networks, and digital infrastructure from both internal and external threats. We offer high-end security solutions to effectively handle critical challenges in the smooth running of your software solutions.
                  </p>
        
          
            </Col>
          </Row>



          </Container>

      <section className="mt-5">

<Container className="database-block">

<Row className="soft-block">
<Col lg={4} sm={12}>
<div className="company-feature"> <span data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={hire} alt="hire" /></span>
<h5 className="text-center mt-5">Dedicated Project Team</h5>
<p> 
We have dedicated resources working for you to make sure that your data & application is secure and without any vulnerabilities. Our development team is committed to providing availability and confidentiality for your data.
     </p>

</div>
</Col>
<Col lg={4} sm={12}>
<div className="company-feature"> <span data-aos="fade-right" data-aos-offset="320" data-aos-easing="ease-in-sine"><img src={content} alt="content" /></span>
<h5 className="text-center mt-5">Tailor-Made Applications

</h5>
<p>
Our security experts can help you in achieving your business objectives by building secure and reliable applications that are suited to your specific business requirements.





    </p>

</div>
</Col>
<Col lg={4} sm={12}>
<div className="company-feature"> <span data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={soft} alt="soft" /></span>
<h5 className="text-center mt-5">Technical Proficiency</h5>
<p>
We incorporate cutting-edge technology and comprehensive security measures throughout the development
 cycle to build applications that are safe from security threats. 


    </p>

</div>
</Col>
<Col lg={4} sm={12}>
<div className="company-feature"> <span data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={sof1} alt="sof1" /></span>
<h5 className="text-center mt-5">Advanced Toolkit</h5>
<p>
To make the application cyber-resilient and functionally robust, we leverage all of the latest and advanced tools & techniques available in the industry. To ensure that the application is functioning without any threats, our team employs comprehensive security measures and uses the most up-to-date testing techniques.


      </p>

</div>
</Col>
<Col lg={4} sm={12}>
<div className="company-feature"> <span data-aos="fade-right" data-aos-offset="320" data-aos-easing="ease-in-sine"><FaSlack/></span>
<h5 className="text-center mt-5">Budget-Friendly

</h5>
<p>
We will do an in-depth analysis of your security requirements in order to precisely scope the project. We've created secure and high-performance software applications for a range of enterprises across numerous sectors while staying within their budget and industry standards.




</p>

</div>
</Col>
<Col lg={4} sm={12}>
<div className="company-feature"> <span data-aos="fade-right" data-aos-offset="320" data-aos-easing="ease-in-sine"><FaSolarPanel/></span>
<h5 className="text-center mt-5">Guaranteed Assistance
</h5>
<p>
Our analysts will assist you in refining your proposal and scoping the project to ensure that your application is protected from cyber-attacks. Our security developers provide maintenance and technical assistance to safeguard your applications once it is been implemented.



</p>

</div>
</Col>

</Row>

</Container>
    </section>


      

      <section className="all-company-feature bg-deep-gray mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
            <h2>Our Recent Works</h2>
            </Col>
          </Row>
          <Row>
          <OwlCarousel className='owl-theme slider-custom' {...options2} loop margin={20} nav>
      
      <div class='item'>
      <div className='work-block-slide'> <img className='img-width-full' src={worka} alt="worka" /> </div>
      </div>
      <div class='item'>
      <div className='work-block-slide'> <img className='img-width-full' src={workb} alt="workb" /> </div>
      </div>
      <div class='item'>
      <div className='work-block-slide'> <img className='img-width-full' src={workc} alt="workc" /> </div>
      </div>
      <div class='item'>
      <div className='work-block-slide'> <img className='img-width-full' src={workd} alt="workd" /> </div>
      </div>
    
      <div class='item'>
      <div className='work-block-slide'> <img className='img-width-full' src={worke} alt="worke" /> </div>
      </div>
      <div class='item'>
      <div className='work-block-slide'> <img className='img-width-full' src={workf} alt="workf" /> </div>
      </div>
      
    </OwlCarousel>
    
          </Row>
        </Container>
      </section>
      <section className="all-company-feature">
        <Container>
          <Row>
            <Col md={12} className="text-center mt-5 pt-5">
            <h2>Our Industry Experience</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu1} alt="indu1" /> </div>
              <h5 className="text-center mt-5">Ecommerce</h5>
              <p> The e-commerce solutions offered at Nexalinx are on-the-go and assured to bring benefits with increased ROI and overall productivity. We provide custom e-commerce development services and develop web apps for any kind of business across various industries and enterprises ranging from B2B and B2C trading networks to small, middle-sized, and large businesses. These custom solutions meet the user’s requirements by applying specific areas of specialization. </p>
            </div>
            </Col>
            <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu2} alt="indu2" /> </div>
              <h5 className="text-center mt-5">Healthcare</h5>
              <p> At Nexalinx, we help you develop healthcare software applications that automate processes such as patient management, lab management, test result analysis, and data collection. For the medical technology companies, from consulting to maintenance and support we always lent our supporting hands to tackle your technological dilemmas. </p>
            </div>
            </Col>
            <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu3} alt="indu3" /> </div>
              <h5 className="text-center mt-5">Logistics</h5>
              <p>The experts at Nexalinx help you build innovative and impressive tech-enabled logistics software applications to streamline the supply chain and provide flawless customer support. The warehouse management software also makes sure to enhance warehouse efficiency. The expert logistics and transportation management software offered by Nexalinx thereby help you do your tasks more effectively.</p>
            </div>
            </Col>
            <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu4} alt="indu4" /> </div>
              <h5 className="text-center mt-5">Education</h5>
              <p> In the education field, Nexalinx is engaged in integrated learning system software development, virtual learning management system, LMS software development, and even personalized e-learning software systems. Interactive, convenient, and cost-effective solutions are what we offer at Nexalinx. </p>
            </div>
            </Col>
            <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu5} alt="indu5" /> </div>
              <h5 className="text-center mt-5">Banking</h5>
              <p> From financial software development, custom banking software development, digital banking software services, and custom Fintech development, we at Nexalinx extend our software development experience in the industry of banking and finance as well. With custom features, and UI/UX designs we ensure the highest standards and security to the software developed. </p>
            </div>
            </Col>
            <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu6} alt="indu6" /> </div>
              <h5 className="text-center mt-5">Manufacturing</h5>
              <p> Implement manufacturing software for ensuring quality management and maintenance software including the application of intelligent products that help in accelerating the existing processes and bringing down operational costs. </p>
            </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Common>
    );
};

export default CyberSecurity;