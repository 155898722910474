

import React from 'react';
import './App.css';
import Routing from './Routing';
import { Toaster } from 'react-hot-toast';


function App() {
  return (
    <div className="App">
    
      <Routing/>
      <Toaster position="bottom-right" reverseOrder={false} />
    </div>
  );
}

export default App;
