import React from 'react';
import Common from "../../layouts/Common"
// import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { FaLockOpen } from 'react-icons/fa';
import { FaGlobe } from 'react-icons/fa';
import { FaWaveSquare } from 'react-icons/fa';
import { FaFileAlt } from 'react-icons/fa';
import { FaFileInvoice } from 'react-icons/fa';
import { FaClock } from 'react-icons/fa';
import { FaLaptop } from 'react-icons/fa';
import { FaMobileAlt } from 'react-icons/fa';
import { FaUserAlt } from 'react-icons/fa';
import { FaCommentAlt } from 'react-icons/fa';
import { FaCommentDots } from 'react-icons/fa';
 import { FaCheckCircle } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FaArrowRight } from 'react-icons/fa';
import { FaSoundcloud } from 'react-icons/fa';
import { FaFileCode } from 'react-icons/fa';
import { FaServer } from 'react-icons/fa';
import { FaSolarPanel } from 'react-icons/fa';
import { FaSearchengin } from 'react-icons/fa';
import { FaScrewdriver } from 'react-icons/fa';
import Container from 'react-bootstrap/Container';
import worka from '../../assets/images/worka.png';
import workb from '../../assets/images/workb.png';
import workc from '../../assets/images/workc.png';
import workd from '../../assets/images/workd.png';
import worke from '../../assets/images/worke.png';
import workf from '../../assets/images/workf.png';
import indu1 from '../../assets/images/indu1.svg';
import indu2 from '../../assets/images/indu2.svg';
import indu3 from '../../assets/images/indu3.svg';
import indu4 from '../../assets/images/indu4.svg';
import indu5 from '../../assets/images/indu5.svg';
import indu6 from '../../assets/images/indu6.svg';
import indcom from '../../assets/images/indcom.svg';
import about from '../../assets/images/about.svg';
import serviceall from '../../assets/images/serviceall.svg';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ContactForm from '../../components/ContactForm';



const Offshore = () => {
  const options2 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items:1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 2,
        },
        1000: {
            items:3,
        }
    },
  };
    return (
<Common>
  <section className="all-sub-banner">
    <div className="sub-banner">
      <Row className="m-0">
        <Col lg={8} sm={12}>
        <div className="slider-banner slide-arrow">
          <h1 className="text-white"><strong>Offshore Software Development Company<br/>
            in USA </strong></h1>
          <p className="text-white p20 mb-4">Offshore software development is the fast-growing trend among 
            IT companies in developed countries where they take services of an Offshore software development 
            company outside their country to get their jobs done at economical cost on a contract basis.
            USA is a hot favorite destination for offshore software development services for such clients.
            But believe us it is not such an easy system, a lot of clients have burned their fingers taking
            the services of vendors who later turned out to be not so efficient and not so expert in the subject. </p>
        </div>
        <Row>
          <Col lg={6} sm={12}>
          <div className="technology-list"> <span>
            <FaLockOpen/>
            </span>
            <label> Security & IP Protection </label>
          </div>
          </Col>
          <Col lg={6} sm={12}>
          <div className="technology-list"> <span>
            <FaGlobe/>
            </span>
            <label> 5 Years Experience </label>
          </div>
          </Col>
          <Col lg={6} sm={12}>
          <div className="technology-list"> <span>
            <FaWaveSquare/>
            </span>
            <label> Flexible Engagement Models </label>
          </div>
          </Col>
          <Col lg={6} sm={12}>
          <div className="technology-list"> <span>
            <FaFileAlt/>
            </span>
            <label> Strict NDA Terms </label>
          </div>
          </Col>
          <Col lg={6} sm={12}>
          <div className="technology-list"> <span>
            <FaFileInvoice/>
            </span>
            <label> No Hidden Costs </label>
          </div>
          </Col>
          <Col lg={6} sm={12}>
          <div className="technology-list"> <span>
            <FaClock/>
            </span>
            <label> On-Time Project Delivery </label>
          </div>
          </Col>
        </Row>
        </Col>
        <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <ContactForm />
        </Col>
      </Row>
    </div>
  </section>
  <section className="all-company-feature mt-5 pt-5">
    <Container>
      <Row>
        <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
        <img className="width-full mt-5" src={indcom} alt="indcom" />
        </Col>
        <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
        <h2>Reliable Offshore Software Development
          Services In USA</h2>
        <p> With the vast experience of working with many US clients we have mastered and trained our team in a way to work
          comfortably with US Clients. We start with an experienced Business Analyst who will understand all your requirements, 
          take your suggestions, give you insight , make a product map and then a Project Manager dedicated for your project who 
          will oversee all the development process from beginning to end and will communicate with you. Today Nexalinx solutions is 
          one of the most reliable and affordable offshore outsourcing service providers in USA for IT services and Software 
          development. For the past 10 years we have been successfully providing offshore software development services to our 
          clients for their different needs with their full satisfaction. USA is home to the fastest and largest offshore 
          software development industry because of the results that industry has seen from USA developers and service providers. 
          We also have been rapidly growing year on year adding new client to our list and providing more outsourcing services to our 
          existing clients and this is because of the fact that we stick by our philosophy of highest quality standards in our services. 
          Your work and investments are being taken care of by Nexalinx solutions during the whole process in offshore outsourcing to 
          Nexalinx USA. Our team consists of very experienced and expert web developers providing offshore software development solutions
          to clients from across the world delivering high quality customized projects and services with high quality and client satisfaction. 
          We are your partners for a skilled, secure, cost effective and flexible offshore center to outsource your projects to us and you 
          focusing on you core business. </p>
        </Col>
      </Row>
    </Container>
  </section>
  <section className="all-company-feature bg-deep-gray mt-5 pt-5">
    <Container>
      <Row>
        <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
        <h2>Why Offshore Software Development
          With Nexalinx Solutions?</h2>
        <p> <strong>Advantages Of Offshore Software Development
          Outsourcing In Bangladesh With Us.</strong> </p>
        <ul className="list-inline list-group ul-list-about mt-4">
          <li> <span>
            <FaCheckCircle/>
            </span>
            <p>Experience & Expertise</p>
          </li>
          <li> <span>
            <FaCheckCircle/>
            </span>
            <p>Cost Effective</p>
          </li>
          <li> <span>
            <FaCheckCircle/>
            </span>
            <p>Flexibility & Versatility</p>
          </li>
          <li> <span>
            <FaCheckCircle/>
            </span>
            <p>Customized Software Solutions</p>
          </li>
        </ul>
        </Col>
        <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
        <img className="abouimg mt-5" src={about} alt="about" />
        </Col>
      </Row>
    </Container>
  </section>
  <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
    <Container>
      <Row>
        <Col md={12} className="text-start mt-4 mb-4">
        <h2>Our Services</h2>
        <p> The software development services provided at Nexalinx will help you with flexible
          and reliable solutions that have proved profitable for various companies from different 
          domains. By employing a technology-driven methodology, we employ customer-friendly software
          applications that have created considerable improvement for business from the bottom line. </p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
        <div className="company-slide mission-vission">
          <Row>
            <Col md={3}>
            <div className="slider-icon icon-all" data-aos="flip-left">
              <FaLaptop/>
            </div>
            </Col>
            <Col md={9} className="ps-0">
            <h5 className='p-0'>UI/UX Design</h5>
            <p className='mb-0'> We combine impressive and rare UI/ UX designs with required 
              functionalities effortlessly. From startups to large organizations, with our UI/UX designs we 
              help organizations to have majestic and customer-centric business solutions with tangible results. </p>
            </Col>
          </Row>
        </div>
        <div className="company-slide mission-vission">
          <Row>
            <Col md={9} className="pe-0 text-end">
            <h5 className='p-0'>Web Development Services</h5>
            <p className='mb-0'> We at Nexalinx provide you with superior web development services combined with superior resources and a technological stack. We also create progressive and native web apps that are highly interactive with distinctive features. We help to strengthen your business strategy by developing web apps that are compatible and scalable. </p>
            </Col>
            <Col md={3}>
            <div className="slider-icon icon-all" data-aos="flip-right">
              <FaMobileAlt/>
            </div>
            </Col>
          </Row>
        </div>
        </Col>
        <Col md={6} className="mt-4" data-aos="fade-left">
        <img className='img-width' src={serviceall} alt="serviceall" />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
        <div className="company-slide mission-vission">
          <Row>
            <Col md={3}>
            <div className="slider-icon icon-all" data-aos="flip-left">
              <FaServer/>
            </div>
            </Col>
            <Col md={9} className="ps-0">
            <h5 className='p-0'>Enterprise Mobility</h5>
            <p className='mb-0'> Hire experts from Nexalinx who have proved their expertise in technology and other business requirements. We provide ideal technological solutions through business process transformations and system integrations. </p>
            </Col>
          </Row>
        </div>
        </Col>
        <Col md={6}>
        <div className="company-slide mission-vission">
          <Row>
            <Col md={9} className="pe-0 text-end">
            <h5 className='p-0'>Application Modernization</h5>
            <p className='mb-0'> At Nexalinx, we fully redesign and refactor existing applications into fully customized and functionally rich applications. The application migration and the reengineering of the software are done by using the aid of new feature enhancements that are advanced, along with employing highly secured cutting-edge technologies and improved UI/UX. </p>
            </Col>
            <Col md={3}>
            <div className="slider-icon icon-all" data-aos="flip-right">
              <FaFileCode/>
            </div>
            </Col>
          </Row>
        </div>
        </Col>
        <Col md={6}>
        <div className="company-slide mission-vission">
          <Row>
            <Col md={3}>
            <div className="slider-icon icon-all" data-aos="flip-left">
              <FaSearchengin/>
            </div>
            </Col>
            <Col md={9} className="ps-0">
            <h5 className='p-0'>Testing & Quality Assurance</h5>
            <p className='mb-0'> The skilful quality analysts at NexaLinx perform the essential checks to ensure that the custom software applications we develop are capable of seamlessly operating across all browsers without any errors. We analyze your idea, target audience, and also monetization strategy to create the best software solution that is suitable to your needs. </p>
            </Col>
          </Row>
        </div>
        </Col>
        <Col md={6}>
        <div className="company-slide mission-vission">
          <Row>
            <Col md={9} className="pe-0 text-end">
            <h5 className='p-0'>Custom Software Development</h5>
            <p className='mb-0'> The custom software development services provided at NexaLinx include designing, developing, and employing customized solutions and services apt for your company. Our domain experts encompass experienced and efficiently engineered software developers with industry-driven experience. We try to employ a dynamic team to provide a solid solution to develop agile solutions with high-quality results. </p>
            </Col>
            <Col md={3}>
            <div className="slider-icon icon-all" data-aos="flip-right">
              <FaSolarPanel/>
            </div>
            </Col>
          </Row>
        </div>
        </Col>
        <Col md={6} >
        <div className="company-slide mission-vission">
          <Row>
            <Col md={9} className="pe-0 text-end">
            <h5 className='p-0'>Cross-Platform App Development</h5>
            <p className='mb-0'> A single app that helps in working along with all other alternative apps is a service that we provide at NexaLinx. It helps to build a mobile solution that is compatible with several systems and operating platforms. Our experts having hands-on experience in various hybrid apps like sencha, AdobePhoneGap, Xamarin and others help in the improvisation of both small-scale and large-scale industries. </p>
            </Col>
            <Col md={3}>
            <div className="slider-icon icon-all" data-aos="flip-right">
              <FaScrewdriver/>
            </div>
            </Col>
          </Row>
        </div>
        </Col>
        <Col md={6}>
        <div className="company-slide mission-vission">
          <Row>
            <Col md={9} className="pe-0 text-end">
            <h5 className='p-0'>Cloud Computing Services</h5>
            <p className='mb-0'> The cloud application development services performed by the team of NexaLinx are highly skilled and hardworking professionals who have the expertise in doing Amazon and Azure cloud application development,SaaS development , and cloud migration. With our experience in cloud-based app development, we deliver solutions that cater to diverse requirements and are applicable to various areas. </p>
            </Col>
            <Col md={3}>
            <div className="slider-icon icon-all" data-aos="flip-right">
              <FaSoundcloud/>
            </div>
            </Col>
          </Row>
        </div>
        </Col>
      </Row>
    </Container>
  </section>
  <section className="all-company-feature bg-deep-gray mt-5 pt-5">
    <Container>
      <Row>
        <Col md={12} className="text-start mt-4 mb-4">
        <h2>Our Recent Works</h2>
        </Col>
      </Row>
      <Row>
      <OwlCarousel className='owl-theme slider-custom' {...options2} loop margin={20} nav>
  
  <div class='item'>
  <div className='work-block-slide'> <img className='img-width-full' src={worka} alt="worka" /> </div>
  </div>
  <div class='item'>
  <div className='work-block-slide'> <img className='img-width-full' src={workb} alt="workb" /> </div>
  </div>
  <div class='item'>
  <div className='work-block-slide'> <img className='img-width-full' src={workc} alt="workc" /> </div>
  </div>
  <div class='item'>
  <div className='work-block-slide'> <img className='img-width-full' src={workd} alt="workd" /> </div>
  </div>

  <div class='item'>
  <div className='work-block-slide'> <img className='img-width-full' src={worke} alt="worke" /> </div>
  </div>
  <div class='item'>
  <div className='work-block-slide'> <img className='img-width-full' src={workf} alt="workf" /> </div>
  </div>
  
</OwlCarousel>

      </Row>
    </Container>
  </section>
  <section className="all-company-feature">
    <Container>
      <Row>
        <Col md={12} className="text-center mt-5 pt-5">
        <h2>Our Industry Experience</h2>
        </Col>
      </Row>
      <Row>
        <Col lg={4} sm={12}>
        <div className="company-feature industry-feature">
          <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu1} alt="indu1" /> </div>
          <h5 className="text-center mt-5">Ecommerce</h5>
          <p> The e-commerce solutions offered at Nexalinx are on-the-go and assured to bring benefits with increased ROI and overall productivity. We provide custom e-commerce development services and develop web apps for any kind of business across various industries and enterprises ranging from B2B and B2C trading networks to small, middle-sized, and large businesses. These custom solutions meet the user’s requirements by applying specific areas of specialization. </p>
        </div>
        </Col>
        <Col lg={4} sm={12}>
        <div className="company-feature industry-feature">
          <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu2} alt="indu2" /> </div>
          <h5 className="text-center mt-5">Healthcare</h5>
          <p> At Nexalinx, we help you develop healthcare software applications that automate processes such as patient management, lab management, test result analysis, and data collection. For the medical technology companies, from consulting to maintenance and support we always lent our supporting hands to tackle your technological dilemmas. </p>
        </div>
        </Col>
        <Col lg={4} sm={12}>
        <div className="company-feature industry-feature">
          <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu3} alt="indu3" /> </div>
          <h5 className="text-center mt-5">Logistics</h5>
          <p>The experts at Nexalinx help you build innovative and impressive tech-enabled logistics software applications to streamline the supply chain and provide flawless customer support. The warehouse management software also makes sure to enhance warehouse efficiency. The expert logistics and transportation management software offered by Nexalinx thereby help you do your tasks more effectively.</p>
        </div>
        </Col>
        <Col lg={4} sm={12}>
        <div className="company-feature industry-feature">
          <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu4} alt="indu4" /> </div>
          <h5 className="text-center mt-5">Education</h5>
          <p> In the education field, Nexalinx is engaged in integrated learning system software development, virtual learning management system, LMS software development, and even personalized e-learning software systems. Interactive, convenient, and cost-effective solutions are what we offer at Nexalinx. </p>
        </div>
        </Col>
        <Col lg={4} sm={12}>
        <div className="company-feature industry-feature">
          <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu5} alt="indu5" /> </div>
          <h5 className="text-center mt-5">Banking</h5>
          <p> From financial software development, custom banking software development, digital banking software services, and custom Fintech development, we at Nexalinx extend our software development experience in the industry of banking and finance as well. With custom features, and UI/UX designs we ensure the highest standards and security to the software developed. </p>
        </div>
        </Col>
        <Col lg={4} sm={12}>
        <div className="company-feature industry-feature">
          <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu6} alt="indu6" /> </div>
          <h5 className="text-center mt-5">Manufacturing</h5>
          <p> Implement manufacturing software for ensuring quality management and maintenance software including the application of intelligent products that help in accelerating the existing processes and bringing down operational costs. </p>
        </div>
        </Col>
      </Row>
    </Container>
  </section>
</Common>
);
};

export default Offshore;