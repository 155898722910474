import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Common from "../../layouts/Common";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { FaBusinessTime } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import { NavLink } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import FreeQuote from '../../pages/FreeQuote';
import Modal from 'react-bootstrap/Modal';
import Mobileapp from './Mobileapp';
import Webapp from './Webapp';
const Portfolio = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [key, setKey] = useState('WebApp');
    return (
  <Common>
 <section className="all-sub-banner portfolio-banner">
    <div className="sub-banner">
      <Row className="m-0">
        <Col lg={8} sm={12}>
        <div className="slider-banner slide-arrow">
            <h4 className="h4project"><FaBusinessTime/>OUR PORTFOLIO</h4>
          <h1 className="text-white"><strong>Some Of Our Finest Works </strong></h1>
          <p className="text-white p20 mb-4">We Have Built High Impact Solutions Across Industries and completed thousands of projects, some of the important projects are shown here. </p>
        </div>
        </Col>
        <Col lg={4} sm={12} className="ps-5">
        <NavLink exact to=""><Button variant="primary btn-custom mat220 btn-big w260"  onClick={handleShow}>Get Started Now! 
        <FaArrowRight className="ms-5" />
        </Button></NavLink>
        </Col>
      </Row>
    </div>
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
    <Modal.Header closeButton className='border-bottom-0'>
    </Modal.Header>
    <Modal.Body>
      <FreeQuote/>
    </Modal.Body>
  </Modal>
  </section>

  <section className="port-sec">
  <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3">
      <Tab eventKey="WebApp" title="Web App">
      <Webapp/>
      </Tab>
      <Tab eventKey="MobileApp" title="Mobile App">
      <Mobileapp/>
      </Tab>
    </Tabs>
  </section>
   </Common>
    );
};

export default Portfolio;