import React from 'react';
import Common from "../../layouts/Common";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Container from 'react-bootstrap/Container';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import resp from '../../assets/images/resp.jpg';
import blog1 from '../../assets/images/blog1.jpg';
import { FaCalendarAlt } from 'react-icons/fa';
import { FaUserAlt } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import { FaArrowRight } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
const Blog = () => {
    const options4 = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        autoplay: true,
        // navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items:1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items:1,
            }
        },
      };
    return (
<Common>
    <section className="all-header"></section>

    <section className="all-company-feature bg-deep-gray bg-deep-gray2 mt-3 pt-3 bg-white certification-slide blog-wr">
    <Container>
      <Row>

        <Col md={12} className="mt-4 mb-4">

        <OwlCarousel className='owl-theme slider-custom slider-custom-two blog-all block-crou' {...options4} loop margin={20} nav>
  
  <div class='item'>
  <Row>

<Col md={7}>
  <ul className="blog-ul">
            <li><FaCalendarAlt/>March 24, 2023</li>
            <li><FaUserAlt/>by Manek</li>
            </ul>
        
        <h2>Differences Between MongoDB & MySQL</h2>

        <p className="pe-5"> 
        When it comes to database management systems, MySQL and MongoDB are certainly the most popular among developers.
Relational systems such as MySQL use tables (containing rows and columns) to store data and make use of structured query languages (such as SQL) for data access. An SQL query generated...
             </p>
             <Col md={12} className="text-start mt-4">
             <NavLink exact to="/blog-details"><Button variant="primary btn-custom mt-3 btn-big w260 w190">Read More
        <FaArrowRight className="ms-5" />
        </Button></NavLink>
        </Col>
             </Col>

             <Col md={5} className="mt-4 mb-4">
             <div className="company-slide">
                <div className="slider-icon2"><img src={blog1} alt="blog1" /> </div>
              </div>
             </Col>
      </Row>
  
  </div>
  <div class='item'>
  <Row>

<Col md={7}>
  <ul className="blog-ul">
            <li><FaCalendarAlt/>March 24, 2023</li>
            <li><FaUserAlt/>by Manek</li>
            </ul>
        
            <h2 className="mt-5">How to Create a Responsive Website Using Full-Stack Development</h2>

<p className="pe-5"> 
A full-stack developer works on both the front and back end of a web application. The front end of a website is in charge of how it looks and feels visually, whereas the back end is in charge of the technology and logic that runs the site from the backend.
In order for a website to dynamically adj...
     </p>
             <Col md={12} className="text-start mt-4">
             <NavLink exact to="/blog-details"><Button variant="primary btn-custom mt-3 btn-big w260 w190">Read More
        <FaArrowRight className="ms-5" />
        </Button></NavLink>
        </Col>
             </Col>

             <Col md={5} className="mt-4 mb-4">
             <div className="company-slide">
                <div className="slider-icon2"><img src={resp} alt="resp" /> </div>
              </div>
             </Col>
      </Row>
  
  </div>



  
</OwlCarousel>

      
        </Col>
      </Row>
    </Container>
  </section>

  {/* <section className="all-company-feature mtp mt-5">
    <Container>
  
      <Row className='mt-4 pt-3'>
        <Col md={4} data-aos="zoom-in">
        <div className='block-list'>
          <div className='block-banner'> <img src={block0} alt="block0" />
            <div className='block-status'>
              <Row>
                <Col md={6}>
                <FaCalendarAlt/>
                Jule 26, 2022
                </Col>
                <Col md={6}>
                <FaCommentDots/>
                Comments (23)
                </Col>
              </Row>
            </div>
          </div>
          <div className='p-4'>
            <h3 className='mt-3 mb-1'>How to Create a Responsive Website Using Full-Stack Development</h3>
            <div className='client-top mt-4'> <img src={user3} alt="user3" className='client-av' />
              <div className='client-details width70'>
                <label> <i>Post By</i> </label>
                <h6 className='mb-1'>Elitha Boram</h6>
              </div>
            </div>
            <div className='clearfix'></div>
            <Col md={12} className="mt-4">
            <p>The need for outsourced software development is growing along with the relevance of software development to businesses. </p>
            <NavLink exact to="/blog-details">Read more...</NavLink>
            </Col>
          </div>
        </div>
        </Col>



        <Col md={4} data-aos="zoom-in">
        <div className='block-list'>
          <div className='block-banner'> <img src={block1} alt="block1" />
            <div className='block-status'>
              <Row>
                <Col md={6}>
                <FaCalendarAlt/>
                Jule 26, 2022
                </Col>
                <Col md={6}>
                <FaCommentDots/>
                Comments (23)
                </Col>
              </Row>
            </div>
          </div>
          <div className='p-4'>
          <h3 className='mt-3 mb-1'>Why ReactJS is a Better Choice for Enterprise App Development</h3>
            <div className='client-top mt-4'> <img src={team2} alt="team2" className='client-av' />
              <div className='client-details width70'>
              <h6 className='mb-1'>Najir Sams</h6>
                <h6 className='mb-1'>Jone Doe</h6>
              </div>
            </div>
            <div className='clearfix'></div>
            <Col md={12} className="mt-4">
            <p>The need for outsourced software development is growing along with the relevance of software development to businesses. </p>
            <NavLink exact to="/blog-details">Read more...</NavLink>
            </Col>
          </div>
        </div>
        </Col>
        <Col md={4} data-aos="zoom-in">
        <div className='block-list'>
          <div className='block-banner'> <img src={block2} alt="block2" />
            <div className='block-status'>
              <Row>
                <Col md={6}>
                <FaCalendarAlt/>
                Jule 26, 2022
                </Col>
                <Col md={6}>
                <FaCommentDots/>
                Comments (23)
                </Col>
              </Row>
            </div>
          </div>
          <div className='p-4'>
            <h3 className='mt-3 mb-1'>Flutter Or React Native? Which Is More Advantageous</h3>
            <div className='client-top mt-4'> <img src={team1} alt="team1" className='client-av' />
              <div className='client-details width70'>
                <label> <i>Post By</i> </label>
                <h6 className='mb-1'>Thomash Jary</h6>
              </div>
            </div>
            <div className='clearfix'></div>
            <Col md={12} className="mt-4">
            <p> The market for mobile app development is expanding quickly, especially due to COVID-19's need for specialized lifestyle and </p>
            <NavLink exact to="/blog-details">ead more...</NavLink>
            </Col>
          </div>
        </div>
        </Col>
    
      </Row>
    </Container>
  </section> */}
</Common>
    );
};

export default Blog;





















