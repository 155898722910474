import React from 'react';
import Common from "../../layouts/Common"
// import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { FaLockOpen } from 'react-icons/fa';
import { FaGlobe } from 'react-icons/fa';
import { FaWaveSquare } from 'react-icons/fa';
import { FaFileAlt } from 'react-icons/fa';
import { FaFileInvoice } from 'react-icons/fa';
import { FaClock } from 'react-icons/fa';
import { FaLaptop } from 'react-icons/fa';
import { FaMobileAlt } from 'react-icons/fa';
import { FaUserAlt } from 'react-icons/fa';
import { FaCommentAlt } from 'react-icons/fa';
import { FaCommentDots } from 'react-icons/fa';
 import { FaCheckCircle } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FaArrowRight } from 'react-icons/fa';
import { FaFileCode } from 'react-icons/fa';
import { FaServer } from 'react-icons/fa';
import { FaSolarPanel } from 'react-icons/fa';
import { FaSearchengin } from 'react-icons/fa';
import Container from 'react-bootstrap/Container';
import worka from '../../assets/images/worka.png';
import workb from '../../assets/images/workb.png';
import workc from '../../assets/images/workc.png';
import workd from '../../assets/images/workd.png';
import worke from '../../assets/images/worke.png';
import workf from '../../assets/images/workf.png';
import indu1 from '../../assets/images/indu1.svg';
import indu2 from '../../assets/images/indu2.svg';
import indu3 from '../../assets/images/indu3.svg';
import indu4 from '../../assets/images/indu4.svg';
import indu5 from '../../assets/images/indu5.svg';
import indu6 from '../../assets/images/indu6.svg';
import indcom from '../../assets/images/indcom.svg';
import about from '../../assets/images/about.svg';
import itconimg from '../../assets/images/it-consulting-service-pic.png';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ContactForm from '../../components/ContactForm';

const Itconsulting = () => {

    const options2 = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        autoplay: true,
        // navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items:1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
            },
            1000: {
                items:3,
            }
        },
      };
        return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
            <div className="slider-banner slide-arrow">
              <h1 className="text-white"><strong>Offshore IT Consulting Services<br/>
                in USA </strong></h1>
              <p className="text-white p20 mb-4">
              NexaLinx Solutions Pvt Ltd is an IT solution provider ,the services enable you to develop sophisticated web and software application capabilities. We understand the importance of a strong web presence in today’s world of increasing Internet dominance. Our IT consulting services are cost-effective and high-quality business process and customer management solutions to companies across different verticals. As a leading IT consulting firm, we help businesses to reinvent and excel by establishing complete reliance on information technology and offering high quality technology consulting services.
                   </p>
            </div>
            <Row>
              <Col lg={6} sm={12}>
              <div className="technology-list"> <span>
                <FaLockOpen/>
                </span>
                <label> Security & IP Protection </label>
              </div>
              </Col>
              <Col lg={6} sm={12}>
              <div className="technology-list"> <span>
                <FaGlobe/>
                </span>
                <label> 5 Years Experience </label>
              </div>
              </Col>
              <Col lg={6} sm={12}>
              <div className="technology-list"> <span>
                <FaWaveSquare/>
                </span>
                <label> Flexible Engagement Models </label>
              </div>
              </Col>
              <Col lg={6} sm={12}>
              <div className="technology-list"> <span>
                <FaFileAlt/>
                </span>
                <label> Strict NDA Terms </label>
              </div>
              </Col>
              <Col lg={6} sm={12}>
              <div className="technology-list"> <span>
                <FaFileInvoice/>
                </span>
                <label> No Hidden Costs </label>
              </div>
              </Col>
              <Col lg={6} sm={12}>
              <div className="technology-list"> <span>
                <FaClock/>
                </span>
                <label> On-Time Project Delivery </label>
              </div>
              </Col>
            </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section>
      <section className="all-company-feature mt-5 pt-5">
        <Container>

        <div className="company-slide mission-vission p-5">
              <Row>
                
                <Col md={12} className="ps-0">
                <h2 className='p-0'>Our IT Consulting Capabilities</h2>
                <p className='mb-0'> The primary goal of our offshore programming bundle innovation counseling administrations is to frame change from non-IT to IT-empowered scene through driving ways that range unit in supreme right with the business needs. we tend to have a go at it in an exceptionally because of encourage our buyers win the first class IT favorable circumstances that grasp decrease in cost, increment in strength and change in general execution. </p>
                </Col>
              </Row>
            </div>



          <Row>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
            <img className="width-full mt-5" src={indcom} alt="indcom" />
            </Col>
            <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
            <h2 className='p-0'>Defining, Designing And Deploying IT Strategies For Business Successs</h2>
                <p className='mb-0'>
                The primary goal of our offshore programming bundle innovation counseling administrations is to frame change from non-IT to IT-empowered scene through driving ways that range unit in supreme right with the business needs. we tend to have a go at it in an exceptionally because of encourage our buyers win the first class IT favorable circumstances that grasp decrease in cost, increment in strength and change in general execution.
<br/><br/>
Our professional offshore services covers areas like software consulting,solutions, Web app Consulting, Work flow management, Software maintenance and so on. We also provide change management services, by training and developing capabilities of the entire staff to enable the employees of your company to adapt to new systems. We ensure training and capability development of the entire staff to make them adept to the new systems. With our offshore IT consultancy services, your company is all set to move to the next level.
                       
                       </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature bg-deep-gray mt-5 pt-5">
        <Container>
          <Row>
            <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
            <h2>Why Choose Us As Your IT
Consultation Company?</h2>
            <p> <strong>Advantages Of Offshore Software Development
              Outsourcing In Bangladesh With Us.</strong> </p>
            <ul className="list-inline list-group ul-list-about mt-4">
              <li> <span>
                <FaCheckCircle/>
                </span>

    
                <p>Expertise

                    <span className="spanlist">NexaLinx offers an interesting blend of business understanding and far reaching innovation mastery to help organizations grasp the maximum capacity of IT.</span>
                </p>
              </li>
              
              <li> <span>
                <FaCheckCircle/>
                </span>
                <p>Businesses Worldwide

                <span className="spanlist">
                Our designers and experts have shown the proficiencies of arranging and actualizing complex IT arrangements in different industry verticals that help organizations develop or overcome normal business challenges
                </span>
                </p>
              </li>
              <li> <span>
                <FaCheckCircle/>
                </span>
                <p>Integrity
                <span className="spanlist">
                Our group insurances to give the best innovation empowered answers for meet your business and IT objectives. The greater part of our administrations and systems depend on industry best practices to give you an answer that will cultivate the development of your business.
                </span>
                </p>
              </li>
            </ul>
            </Col>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
            <img className="abouimg mt-5" src={about} alt="about" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
            <h2>Our IT Consulting Services</h2>
            <p> Create a comprehensive IT strategy for a digital and technological transformation of your business that goes in check with your organizational objectives with the help of Nexalinx. Our strategic IT consulting will assist you to resolve complex software development concerns to strategic digital transformation. At Nexalinx, we offer IT consulting services that will help you to upgrade and refine your software architecture, create a tech-driven digital strategy, and improve operations by optimizing your software portfolio. Our software development professionals assist organizations in transforming and gaining a competitive advantage.</p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
            <div className="company-slide mission-vission">
              <Row>
                <Col md={3}>
                <div className="slider-icon icon-all" data-aos="flip-left">
                  <FaLaptop/>
                </div>
                </Col>
                <Col md={9} className="ps-0">
                <h5 className='p-0'>Enterprise
Architecture Advisory</h5>
                <p className='mb-0'> At NexaLinx, our Enterprise Architecture experts are ready to provide the 
                assistance to make the transition from outdated and ineffective IT delivery systems and 
                architectures to a cloud-based infrastructure that streamlines delivery and allows for 
                the implementation of new technologies into your software strategy. We provide insights-driven 
                perspectives for developing IT strategies that correspond to business priorities. We also accelerate 
                technological and creative innovations by building industry-specific solutions and frameworks to deliver
                 new accomplishments and proficiencies. At NexaLinx, we focus on simplified and agile architecture processes 
                 resulting in better IT responses. </p>
                </Col>
              </Row>
            </div>
            <div className="company-slide mission-vission">
              <Row>
                <Col md={9} className="pe-0 text-end">
                <h5 className='p-0'>Infrastructure
Management Services</h5>
                <p className='mb-0'> Infrastructure management services include preparation, design, execution, maintenance, and evolution of IT infrastructure. Nexalinx has been assisting businesses in keeping their cloud infrastructures, enterprise systems, networks, and applications reliable, highly available, and cost-effective. Our highly skilled professionals strive for measurable outcomes at all times.</p>
                </Col>
                <Col md={3}>
                <div className="slider-icon icon-all" data-aos="flip-right">
                  <FaMobileAlt/>
                </div>
                </Col>
              </Row>
            </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
            <img className='img-width' src={itconimg} alt="serviceall" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
            <div className="company-slide mission-vission pb-5">
              <Row>
                <Col md={3}>
                <div className="slider-icon icon-all" data-aos="flip-left">
                  <FaServer/>
                </div>
                </Col>
                <Col md={9} className="ps-0">
                <h5 className='p-0'>IT Service
Management Consulting</h5>
                <p className='mb-0 pb-4'> IT Service Management is the ongoing process of managing a company's hardware, software, and computing resources. Nexalinx will leverage its IT Service management consulting experience to assist you in improving the quality and speed of IT service delivery. At Nexalinx, our consultants Plan and implement IT service management processes that are tailored to your organization's specific IT environment. We ensure to strengthen your company's incident management, IT asset management, change management, and knowledge management. </p>
                </Col>
              </Row>
            </div>
            </Col>
            <Col md={6}>
            <div className="company-slide mission-vission">
              <Row>
                <Col md={9} className="pe-0 text-end">
                <h5 className='p-0'>IT Project And Program
Management Consulting</h5>
                <p className='mb-0'> IT project management consulting entails hiring third-party experts to plan and oversee IT projects in order to complete them more quickly and in accordance with business requirements. We provide project management consulting services to assist you in delivering high-quality IT initiatives.We consider your project status and the hurdles you face in order to focus our consulting interaction on your sticking points and ensure that your return on investment is measurable. Project assessment, project resource management, and project control are all part of our service scope. </p>
                </Col>
                <Col md={3}>
                <div className="slider-icon icon-all" data-aos="flip-right">
                  <FaFileCode/>
                </div>
                </Col>
              </Row>
            </div>
            </Col>
            <Col md={6}>
            <div className="company-slide mission-vission">
              <Row>
                <Col md={3}>
                <div className="slider-icon icon-all" data-aos="flip-left">
                  <FaSearchengin/>
                </div>
                </Col>
                <Col md={9} className="ps-0">
                <h5 className='p-0'>IT Strategy
Consulting And New Tech</h5>
                <p className='mb-0'> In this modern digital era with high competition, we ensure the consistent and systematic delivery of high-quality IT products and services for your enterprise functions as well as proper workflow for the customers. To be competitive and fulfill the demands of modern users, we integrate new technologies and get updated each day. Our IT consulting services will help you to strike a balance between these two objectives, ensuring that they do not conflict. </p>
                </Col>
              </Row>
            </div>
            </Col>
            <Col md={6}>
            <div className="company-slide mission-vission">
              <Row>
                <Col md={9} className="pe-0 text-end">
                <h5 className='p-0'>Technology
Consulting</h5>
                <p className='mb-0'>The vision is to incorporate impactful digital technologies and introduce major changes to your business model and mechanisms. IT consultants at Nexalinx work with disruptive technologies such as Cloud computing, Cybersecurity, Machine learning, Artificial intelligence, Big data, Data Science, Blockchain, IoT, Virtual reality, Augmented reality, Computer vision, and Multi-component ERP. </p>
                </Col>
                <Col md={3}>
                <div className="slider-icon icon-all" data-aos="flip-right">
                  <FaSolarPanel/>
                </div>
                </Col>
              </Row>
            </div>
            </Col>
           
          </Row>
        </Container>
      </section>
      <section className="all-company-feature bg-deep-gray mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
            <h2>Our Recent Works</h2>
            </Col>
          </Row>
          <Row>
          <OwlCarousel className='owl-theme slider-custom' {...options2} loop margin={20} nav>
      
      <div class='item'>
      <div className='work-block-slide'> <img className='img-width-full' src={worka} alt="worka" /> </div>
      </div>
      <div class='item'>
      <div className='work-block-slide'> <img className='img-width-full' src={workb} alt="workb" /> </div>
      </div>
      <div class='item'>
      <div className='work-block-slide'> <img className='img-width-full' src={workc} alt="workc" /> </div>
      </div>
      <div class='item'>
      <div className='work-block-slide'> <img className='img-width-full' src={workd} alt="workd" /> </div>
      </div>
    
      <div class='item'>
      <div className='work-block-slide'> <img className='img-width-full' src={worke} alt="worke" /> </div>
      </div>
      <div class='item'>
      <div className='work-block-slide'> <img className='img-width-full' src={workf} alt="workf" /> </div>
      </div>
      
    </OwlCarousel>
    
          </Row>
        </Container>
      </section>
      <section className="all-company-feature">
        <Container>
          <Row>
            <Col md={12} className="text-center mt-5 pt-5">
            <h2>Our Industry Experience</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu1} alt="indu1" /> </div>
              <h5 className="text-center mt-5">Ecommerce</h5>
              <p> The e-commerce solutions offered at Nexalinx are on-the-go and assured to bring benefits with increased ROI and overall productivity. We provide custom e-commerce development services and develop web apps for any kind of business across various industries and enterprises ranging from B2B and B2C trading networks to small, middle-sized, and large businesses. These custom solutions meet the user’s requirements by applying specific areas of specialization. </p>
            </div>
            </Col>
            <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu2} alt="indu2" /> </div>
              <h5 className="text-center mt-5">Healthcare</h5>
              <p> At Nexalinx, we help you develop healthcare software applications that automate processes such as patient management, lab management, test result analysis, and data collection. For the medical technology companies, from consulting to maintenance and support we always lent our supporting hands to tackle your technological dilemmas. </p>
            </div>
            </Col>
            <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu3} alt="indu3" /> </div>
              <h5 className="text-center mt-5">Logistics</h5>
              <p>The experts at Nexalinx help you build innovative and impressive tech-enabled logistics software applications to streamline the supply chain and provide flawless customer support. The warehouse management software also makes sure to enhance warehouse efficiency. The expert logistics and transportation management software offered by Nexalinx thereby help you do your tasks more effectively.</p>
            </div>
            </Col>
            <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu4} alt="indu4" /> </div>
              <h5 className="text-center mt-5">Education</h5>
              <p> In the education field, Nexalinx is engaged in integrated learning system software development, virtual learning management system, LMS software development, and even personalized e-learning software systems. Interactive, convenient, and cost-effective solutions are what we offer at Nexalinx. </p>
            </div>
            </Col>
            <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu5} alt="indu5" /> </div>
              <h5 className="text-center mt-5">Banking</h5>
              <p> From financial software development, custom banking software development, digital banking software services, and custom Fintech development, we at Nexalinx extend our software development experience in the industry of banking and finance as well. With custom features, and UI/UX designs we ensure the highest standards and security to the software developed. </p>
            </div>
            </Col>
            <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu6} alt="indu6" /> </div>
              <h5 className="text-center mt-5">Manufacturing</h5>
              <p> Implement manufacturing software for ensuring quality management and maintenance software including the application of intelligent products that help in accelerating the existing processes and bringing down operational costs. </p>
            </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Common>
    );
};

export default Itconsulting;