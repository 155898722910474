// import Container from 'react-bootstrap/Container';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React from 'react';
import Common from "../../layouts/Common";
// import Container from 'react-bootstrap/Container';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { FaArrowRight, FaCheckCircle, FaClock, FaCommentAlt, FaCommentDots, FaFileAlt, FaFileCode, FaFileInvoice, FaGlobe, FaLaptop, FaLockOpen, FaMobileAlt, FaScrewdriver, FaSearchengin, FaServer, FaSolarPanel, FaSoundcloud, FaUserAlt, FaWaveSquare } from 'react-icons/fa';
import indcom from '../../assets/images/indcom.svg';
import serviceall from '../../assets/images/serviceall.svg';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ContactForm from '../../components/ContactForm';
import IndustryExperience from '../../components/IndustryExperience';
import RecentWork from '../../components/RecentWork';



const Php = () => {

  const iconList = [FaArrowRight, FaCheckCircle, FaClock, FaCommentAlt, FaCommentDots, FaFileAlt, FaFileCode, FaFileInvoice, FaGlobe, FaLaptop, FaLockOpen, FaMobileAlt, FaScrewdriver, FaSearchengin, FaServer, FaSolarPanel, FaSoundcloud, FaUserAlt, FaWaveSquare];
  const iconList2 = ['FaArrowRight', 'FaCheckCircle', 'FaClock', 'FaCommentAlt', 'FaCommentDots', 'FaFileAlt', 'FaFileCode', 'FaFileInvoice', 'FaGlobe', 'FaLaptop', 'FaLockOpen', 'FaMobileAlt', 'FaScrewdriver', 'FaSearchengin', 'FaServer', 'FaSolarPanel', 'FaSoundcloud', 'FaUserAlt', 'FaWaveSquare'];

  const serviceData =
  {
    "title": "PHP Web Development Company in Bangladesh",
    "subTitle": "Nexalinx Solutions is one of the top PHP web development companies in Bangladesh providing world-class PHP development services to its esteemed and valuable clients in Bangladesh and also offshore development services to global clients for the past 1 years. The list of our clients is ever-expanding at the pace at which internet technologies are developing and new trends coming in the market. Most of the people are connected to the internet especially in the developed countries and it becomes important for businesses to make their presence on the internet through a website or mobile application.",
    "feature": [
      {
        "title": "SECURITY & IP PROTECTION",
        "icon": "FaLockOpen"
      },
      {
        "title": "1 YEARS EXPERIENCE",
        "icon": "FaGlobe"
      },
      {
        "title": "FLEXIBLE ENGAGEMENT MODELS",
        "icon": "FaWaveSquare"
      },
      {
        "title": "STRICT NDA TERMS",
        "icon": "FaFileAlt"
      },
      {
        "title": "NO HIDDEN COSTS",
        "icon": "FaFileInvoice"
      },
      {
        "title": "ON-TIME PROJECT DELIVERY",
        "icon": "FaClock"
      }
    ],
    "heading_one": {
      "title": "Why Choose Nexalinx Solutions For Your PHP Web Development",
      "details": "Most of the people now have mobile and do the search for services and products on internet so it has become necessary for businesses to have a website where people can reach you for your own profit. PHP is a website development language by which you can get a very simple website very easily, fast and at very affordable rate to start with and later upgrade and scale it to any level. Nexalinx Solutions is one of the best offshore software development company in Bangladesh with a terrific track record of successfully delivering several PHP website from simple to complex dynamic PHP portals to our clients. Our teams of PHP developers have the vast experience and expertise in PHP technology to deliver complex custom PHP web applications to our clients that were made as per the latest technology trend and capable of easy up-gradation as per future needs. Our PHP web solutions run smoothly on desktops, laptops or on mobile and go through a stringent quality and testing process to ensure that our clients get the best quality and reliable applications. We are also one of the renowned names in providing offshore PHP development services to our international clients from our development centre in Bangladesh thus they get world class quality at affordable cost.",
      "image": "https://www.image.com"
    },
    "heading_two": {
      "title": "Advantages of outsourcing",
      "details": "",
      "feature_point": [
        {
          "title": "",
          "icon": "FaCheckCircle"
        }
      ],
      "image": "https://www.image.com"
    },
    "service_items": {
      "title": "Our PHP Development Services",
      "subTitle": "",
      "image": "https://www.image.com",
      "items": [
        {
          "title": "PHP Support And Maintenance",
          "details": "Optimize your business processes by obtaining the highest possible level of maintenance and support from us. We can provide outstanding support and maintenance services to ensure smooth performance and seamless navigation of your PHP applications. Our trained PHP developers can maximize the efficiency of your application and help you stay ahead of the competition. We can deliver effective maintenance solutions that remove all the glitches from your software and help you escalate your business growth. By working with us, you can be sure to receive timely support, intuitive responses and effective maintenance. Our PHP developers have years of experience in testing and they make sure that your PHP apps are both user-friendly and secure. We keep ourselves up-to-date with the latest technology changes in PHP so that your apps are upgraded accordingly and made to run in a smooth and efficient manner."
        },
        {
          "title": "PHP Web App Development",
          "details": "We provide top PHP development services in Bangladesh to build dynamic web applications with the advanced use of the PHP framework. We have stellar experience in various PHP frameworks like Codeigniter, Laravel, Symphony, CakePHP, Yii, etc. to offer customized PHP web app development solutions. We develop unique and database-driven web apps with optimum performance for businesses of all sizes and types. We can deliver highly-functioning web solutions that fit all your business needs within agreed timelines. Different frameworks can be used to offer perks such as speed, security, scalability, customizability, performance, ease of installation, flexibility, stability, configurability and high integration. They can be used to provide features such as asset management, session management, caching, autoloading, cloud API, data encryption, good documentation, engine independence, extensions and support tools, among others. We focus on the right ways to integrate these features onto your web app."
        },
        {
          "title": "PHP E-Commerce Development",
          "details": "We specialize in transforming the eCommerce industry by creating PHP-powered online retail applications. Our skilled pool of developers can create world-class eCommerce solutions to meet the needs of your business. We have the technical know-how to build PHP e-commerce sites for your business that will engage new customers, retain old ones, and generate leads. Being one of the leading custom PHP development companies in Bangladesh, we can develop stunning e-stores that provide a great purchasing experience for your customers and improve your brand image. eCommerce web development involves developing the technical aspects of your eCommerce website. These aspects include the functionality of your eStore, payment gateway support, app responses to user actions, and the aesthetics of your app, to name a few. It is undeniable that eCommerce web development is absolutely critical to the success of your online business, and you can be assured that we will make its success certain."
        },
        {
          "title": "PHP API Development & Integrations",
          "details": "We have a proficient team of developers who can create API solutions that are secure, scalable and fulfill your business needs. We work diligently to provide enterprise-level API development services that are both high-performing and adaptable while remaining within your budget. We can assist you in developing dependable APIs that allow you to effectively integrate your apps with other systems. As a supreme PHP website development company in Bangladesh, we can provide top-notch PHP development services in Bangladesh to satisfy all your API-related requirements and help you extend and improve the functionality of web applications. API solutions allow apps to communicate with each other. They ensure that the apps designed for and by your business integrate seamlessly with other systems and apps, both on your customers’ devices and on your business’s digital infrastructure. Thanks to APIs, there is no need to build any supporting apps from scratch to ensure the smooth functioning of your eStore: it can simply use a previously-existing app on your customer’s device to complete its function."
        },
        {
          "title": "PHP Migration And Upgradation",
          "details": "Our talented PHP professionals can provide comprehensive migration services to move your legacy application to a more robust and intuitive PHP framework. Get the best help from our experienced PHP experts to ensure a smooth transition to the new technology so that you can improve your website's performance. We are adept at upgrading your existing PHP application to its latest version to increase security, customization, & flexibility. We are one of the eminent offshore PHP development companies in Bangladesh that has competent developers who deliver the best migration and upgradation services with complete data security. PHP upgradation improves security and increases performance. PHP upgrades can provide new language features, extensions and libraries distinct from the ones in the previous PHP version, benefitting both QA and maintenance cycles. Regulations by compliance authorities can demand up-to-date encryption standards and this may ultimately require a PHP migration. By choosing us for your PHP migration, you can be guaranteed a smooth transition."
        },
        {
          "title": "QA And Testing For PHP Applications",
          "details": "We have a group of technically skilled individuals dedicated entirely to delivering bug-free, completely functional, and hassle-free PHP applications to the customer within the specified time frame. We offer a tailored service to satisfy all of our customers' quality assurance needs. We utilize both manual and automated testing to ensure that our products are able to surpass the expected quality standards. Our software testing professionals apply a laser-like focus to help clients get the most out of our testing efforts while reducing time to market and increasing ROI. During our manual testing cycles, our testers and developers work on executing test cases in a hands-on approach, giving your apps the attention they need to score well on industrial benchmarks. Testers with ample experience in using tools, scripts and software to test apps via automation are also employed to ensure state-of-the-art quality. As you can guess, testing is an important aspect of the app development cycle, and we give it the importance it deserves."
        },
        {
          "title": "PHP CMS Development",
          "details": "As a renowned PHP development company in Bangladesh, we have trained developers who can build dynamic and database-driven CMS websites using PHP and MySQL. Our team of dedicated technical specialists will assist you in propelling your business to new heights by creating and administering dynamic and user-friendly CMS websites that can be set up easily. We create intuitive CMS solutions that offer end-users with varying permissions to maintain or even access the organization's information, content, and assets. Our PHP solutions assist businesses in establishing a cost-effective and economically sustainable virtual presence on the internet.A Content Management System (CMS) is the name given to a software that helps users build, create, manage and modify content on a website without the need for specialized technical or coding knowledge. It does not matter whether your business has a dedicated web developer: using CMS, anyone can add website content in a number of simple steps."
        }
      ]
    }
  };

  const renderFeature = (data, index) => {
    const Icon = iconList2.findIndex(element => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (

      <Col lg={6} sm={12} key={index}>
        <div className="technology-list"> <span>
          <Icon2 />
        </span>
          <label> {data.title} </label>
        </div>
      </Col>
    );
  };

  const renderlist = (data, index) => {
    const Icon = iconList2.findIndex(element => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (

      <li key={index}> <span>
        <Icon2 />
      </span>
        <p>{data.title}</p>
      </li>
    );
  };

  const renderServiceLeft = (data, index) => {
    const Icon = iconList2.findIndex(element => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (

      <div className="company-slide mission-vission" key={index}>
        <Row>
          <Col md={3}>
            <div className="slider-icon icon-all" data-aos="flip-left">
              <Icon2 />
            </div>
          </Col>
          <Col md={9} className="ps-0">
            <h5 className='p-0'>{data.title}</h5>
            <p className='mb-0'> {data.details} </p>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white"><strong>{serviceData.title}</strong></h1>
                <p className="text-white p20 mb-4">{serviceData.subTitle}</p>
              </div>
              <Row>
                {serviceData.feature.map(data => renderFeature(data))}
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section>
      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <img className="width-full mt-5" src={indcom} alt="indcom" />
            </Col>
            <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
              <h2>{serviceData?.heading_one?.title}</h2>
              <p>{serviceData?.heading_one?.details}</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2>{serviceData.service_items.title}</h2>
              <p> {serviceData.service_items.subTitle} </p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaLaptop />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className='p-0'>{serviceData.service_items?.items[0].title}</h5>
                    <p className='mb-0'> {serviceData.service_items?.items[0].details} </p>
                  </Col>
                </Row>
              </div>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className='p-0'>{serviceData.service_items?.items[1].title}</h5>
                    <p className='mb-0'> {serviceData.service_items?.items[1].details} </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaMobileAlt />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className='img-width' src={serviceall} alt="serviceall" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaServer />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className='p-0'>{serviceData.service_items?.items[2].title}</h5>
                    <p className='mb-0'> {serviceData.service_items?.items[2].details} </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className='p-0'>{serviceData.service_items?.items[3].title}</h5>
                    <p className='mb-0'> {serviceData.service_items?.items[3].details} </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaFileCode />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaSearchengin />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className='p-0'>{serviceData.service_items?.items[4].title}</h5>
                    <p className='mb-0'> {serviceData.service_items?.items[4].details} </p>
                  </Col>
                </Row>
              </div>
            </Col>

            {serviceData.service_items?.items[5] &&
              <Col md={6}>
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className='p-0'>{serviceData.service_items?.items[5]?.title}</h5>
                      <p className='mb-0'> {serviceData.service_items?.items[5]?.details} </p>
                    </Col>
                    <Col md={3}>
                      <div className="slider-icon icon-all" data-aos="flip-right">
                        <FaSolarPanel />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            }
            {serviceData.service_items?.items[6] &&
              <Col md={6} >
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className='p-0'>{serviceData.service_items?.items[6]?.title}</h5>
                      <p className='mb-0'> {serviceData.service_items?.items[6]?.details} </p>
                    </Col>
                    <Col md={3}>
                      <div className="slider-icon icon-all" data-aos="flip-right">
                        <FaScrewdriver />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            }
            {serviceData.service_items?.items[7] &&
              <Col md={6}>
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className='p-0'>{serviceData.service_items?.items[7]?.title}</h5>
                      <p className='mb-0'> {serviceData.service_items?.items[7]?.details} </p>
                    </Col>
                    <Col md={3}>
                      <div className="slider-icon icon-all" data-aos="flip-right">
                        <FaSoundcloud />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            }

          </Row>
        </Container>
      </section>
      <RecentWork />
      <IndustryExperience />
    </Common>
  )
};

export default Php;