import React from 'react';
import Common from "../../layouts/Common";
// import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { FaClock, FaFileAlt, FaFileInvoice, FaGlobe, FaLaptop, FaLockOpen, FaMobileAlt, FaWaveSquare } from 'react-icons/fa';

import { FaCommentAlt, FaCommentDots, FaUserAlt } from 'react-icons/fa';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { FaArrowRight, FaCartPlus, FaScrewdriver, FaSearchengin, FaServer } from 'react-icons/fa';
import ande from '../../assets/images/ande.svg';
import angularui from '../../assets/images/angularui.svg';
import indu1 from '../../assets/images/indu1.svg';
import indu2 from '../../assets/images/indu2.svg';
import indu3 from '../../assets/images/indu3.svg';
import indu4 from '../../assets/images/indu4.svg';
import indu5 from '../../assets/images/indu5.svg';
import indu6 from '../../assets/images/indu6.svg';
import worka from '../../assets/images/worka.png';
import workb from '../../assets/images/workb.png';
import workc from '../../assets/images/workc.png';
import workd from '../../assets/images/workd.png';
import worke from '../../assets/images/worke.png';
import workf from '../../assets/images/workf.png';


import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import ContactForm from '../../components/ContactForm';



const Angularjs = () => {
  const options2 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      }
    },
  };
  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white"><strong>Offshore Software Development Company<br />
                  in Bangladesh </strong></h1>
                <p className="text-white p20 mb-4">Offshore software development is the fast-growing trend among
                  IT companies in developed countries where they take services of an Offshore software development
                  company outside their country to get their jobs done at economical cost on a contract basis.
                  Bangladesh is a hot favorite destination for offshore software development services for such clients.
                  But believe us it is not such an easy system, a lot of clients have burned their fingers taking
                  the services of vendors who later turned out to be not so efficient and not so expert in the subject. </p>
              </div>
              <Row>
                <Col lg={6} sm={12}>
                  <div className="technology-list"> <span>
                    <FaLockOpen />
                  </span>
                    <label> Security & IP Protection </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list"> <span>
                    <FaGlobe />
                  </span>
                    <label> 15 Years Experience </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list"> <span>
                    <FaWaveSquare />
                  </span>
                    <label> Flexible Engagement Models </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list"> <span>
                    <FaFileAlt />
                  </span>
                    <label> Strict NDA Terms </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list"> <span>
                    <FaFileInvoice />
                  </span>
                    <label> No Hidden Costs </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list"> <span>
                    <FaClock />
                  </span>
                    <label> On-Time Project Delivery </label>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section>
      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <img className="angularblock" src={angularui} alt="angularui" />
            </Col>
            <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
              <h2>Our Angular<br />
                Development Capabilities</h2>
              <p> We provide scalable and affordable angular development solutions to build dynamic websites and our developers are well versed with the latest developments in the field, making Nexalinx Solutions the best company to hire Angular Developers in Bangladesh. Get the best development solutions for your business with
                our flexible hiring models and custom angular components. Hire our robust team of experienced Angular developers and get quality service delivered with a professional approach. </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature mb-5 all-services mt-service">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2>Hire Angular Developers In Bangladesh From Nexalinx Solutions</h2>
              <p>Being one of the leading Angular JS development companies in Bangladesh, Nexalinx offers exemplary development services to our clients. We help business to develop high performance app using our Angular JS services. Our Angualr JS development services in Bangladesh comprise:</p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaLaptop />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className='p-0'>Web App Development</h5>
                    <p className='mb-0'> Our proficient Angular JS developers will provide solutions for your mission-critical business operations exactly
                      how you want them. As AngularJS is developer-friendly and robust, we can smoothly develop applications with quality code. With us,
                      you will gain access to top-tier AngularJS professionals who have built significant, adaptable, dynamic, and interactive solutions with
                      exceptional web performance. Utilize our bespoke services to create high-performing and cost-effective applications that satisfy
                      your unique business requirements. </p>
                  </Col>
                </Row>
              </div>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className='p-0'>Mobile App Development</h5>
                    <p className='mb-0'>We leverage Ionic and Cordova technologies to build mobile apps for our clients. Because of AngularJs' code reusable property, the development process is both seamless and fast. By leveraging our effective AngularJS services, you can gain high-quality traffic, improved retention, and more market attention. AngularJS has a component-based MVC architecture that enables us to offer mobile apps having a great look, feel, and user experience of native apps to a large extent. </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaMobileAlt />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className='img-width' src={ande} alt="ande" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaServer />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className='p-0'>API Integration</h5>
                    <p className='mb-0'> Delivering highly effective Angular JS development services in Bangladesh, our developers always make sure that we provide reliable API integration services that can enhance your business productivity. We can help you exchange all kinds of data between different applications to easily execute various types of tasks. We properly implement API to provide critical functionality in an application. We offer complete assistance and ensure that the connections between services run smoothly and securely. </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className='p-0'>E-Commerce</h5>
                    <p className='mb-0'> If you're looking for an ideal e-commerce site for your company, we are here to help you with all of your development needs using the most up-to-date technology. We can develop an optimal e-commerce store to help you grow your online business. With the right store created by our team of talented developers, you can attract a huge number of buyers. Our team has the knowledge and experience to suit the various needs of merchants looking for the best e-commerce solutions. </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaCartPlus />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaSearchengin />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className='p-0'>QA And Testing Services</h5>
                    <p className='mb-0'> Our proficient Angular JS developers will provide solutions for your mission-critical business operations exactly
                      how you want them. As AngularJS is developer-friendly and robust, we can smoothly develop applications with quality code. With us,
                      you will gain access to top-tier AngularJS professionals who have built significant, adaptable, dynamic, and interactive solutions with
                      exceptional web performance. Utilize our bespoke services to create high-performing and cost-effective applications that satisfy
                      your unique business requirements. </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4 mb-4">
              <div className="company-slide mission-vission mt-4">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className='p-0'>Support And Maintenance</h5>
                    <p className='mb-0'>Our expert developers examine the current application's features and challenges encountered by the client and implement standardized techniques to
                      improve the system. We can assist you in optimizing your applications by modifying them effectively while maintaining their stability and functionality.
                      To improve client interaction, we can add new features to your applications and remove non-functional elements. </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaScrewdriver />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature bg-deep-gray mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2>Our Recent Works</h2>
            </Col>
          </Row>
          <Row>
            <OwlCarousel className='owl-theme slider-custom' {...options2} loop margin={20} nav>

              <div class='item'>
                <div className='work-block-slide'> <img className='img-width-full' src={worka} alt="worka" /> </div>
              </div>
              <div class='item'>
                <div className='work-block-slide'> <img className='img-width-full' src={workb} alt="workb" /> </div>
              </div>
              <div class='item'>
                <div className='work-block-slide'> <img className='img-width-full' src={workc} alt="workc" /> </div>
              </div>
              <div class='item'>
                <div className='work-block-slide'> <img className='img-width-full' src={workd} alt="workd" /> </div>
              </div>

              <div class='item'>
                <div className='work-block-slide'> <img className='img-width-full' src={worke} alt="worke" /> </div>
              </div>
              <div class='item'>
                <div className='work-block-slide'> <img className='img-width-full' src={workf} alt="workf" /> </div>
              </div>

            </OwlCarousel>

          </Row>
        </Container>
      </section>
      <section className="all-company-feature">
        <Container>
          <Row>
            <Col md={12} className="text-center mt-5 pt-5">
              <h2>Our Industry Experience</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu1} alt="indu1" /> </div>
                <h5 className="text-center mt-5">Ecommerce</h5>
                <p> The e-commerce solutions offered at Nexalinx are on-the-go and assured to bring benefits with increased ROI and overall productivity. We provide custom e-commerce development services and develop web apps for any kind of business across various industries and enterprises ranging from B2B and B2C trading networks to small, middle-sized, and large businesses. These custom solutions meet the user’s requirements by applying specific areas of specialization. </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu2} alt="indu2" /> </div>
                <h5 className="text-center mt-5">Healthcare</h5>
                <p> At Nexalinx, we help you develop healthcare software applications that automate processes such as patient management, lab management, test result analysis, and data collection. For the medical technology companies, from consulting to maintenance and support we always lent our supporting hands to tackle your technological dilemmas. </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu3} alt="indu3" /> </div>
                <h5 className="text-center mt-5">Logistics</h5>
                <p>The experts at Nexalinx help you build innovative and impressive tech-enabled logistics software applications to streamline the supply chain and provide flawless customer support. The warehouse management software also makes sure to enhance warehouse efficiency. The expert logistics and transportation management software offered by Nexalinx thereby help you do your tasks more effectively.</p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu4} alt="indu4" /> </div>
                <h5 className="text-center mt-5">Education</h5>
                <p> In the education field, Nexalinx is engaged in integrated learning system software development, virtual learning management system, LMS software development, and even personalized e-learning software systems. Interactive, convenient, and cost-effective solutions are what we offer at Nexalinx. </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu5} alt="indu5" /> </div>
                <h5 className="text-center mt-5">Banking</h5>
                <p> From financial software development, custom banking software development, digital banking software services, and custom Fintech development, we at Nexalinx extend our software development experience in the industry of banking and finance as well. With custom features, and UI/UX designs we ensure the highest standards and security to the software developed. </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div className='indus-img' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><img src={indu6} alt="indu6" /> </div>
                <h5 className="text-center mt-5">Manufacturing</h5>
                <p> Implement manufacturing software for ensuring quality management and maintenance software including the application of intelligent products that help in accelerating the existing processes and bringing down operational costs. </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Common>
  );
};

export default Angularjs;