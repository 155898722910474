import React from 'react';
import { HashRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from './components/ScrollTop';
import Contact from './pages/Contact';
import FreeQuote from './pages/FreeQuote';
import Aboutus from './pages/about/Aboutus';
import Team from './pages/about/Team';
import Blog from './pages/blog/Blog';
import Blogdetails from './pages/blog/Blogdetails';
import Home from './pages/home/Home';
import Mission from './pages/home/Mission';
import Vision from './pages/home/Vision';
import Portfolio from './pages/portfolio/Portfolio';
import Amazonweb from './pages/services/Amazonweb';
import CyberSecurity from './pages/services/CyberSecurity';
import DatabaseManagementServices from './pages/services/DatabaseManagementServices';
import Development from './pages/services/Development';
import ErpSoftwareDevelopment from './pages/services/ErpSoftwareDevelopment';
import HireDedicated from './pages/services/HireDedicated';
import Itconsulting from './pages/services/Itconsulting';
import JavaDevelopment from './pages/services/JavaDevelopment';
import Offshore from './pages/services/Offshore';
import Progressivewebapp from './pages/services/Progressivewebapp';
import SoftwareMaintenance from './pages/services/SoftwareMaintenance';
import SoftwareOutsourcing from './pages/services/SoftwareOutsourcing';
import SoftwareQaTestingServices from './pages/services/SoftwareQaTestingServices';
import UIUXDesign from './pages/services/UIUXDesign';
import Angularjs from './pages/technologies/Angularjs';
import Aspnet from './pages/technologies/Aspnet';
import Laravel from './pages/technologies/Laravel';
import Magento from './pages/technologies/Magento';
import NodeJs from './pages/technologies/NodeJs';
import Php from './pages/technologies/Php';
import Python from './pages/technologies/Python';
import ReactJs from './pages/technologies/ReactJs';
import VueJs from './pages/technologies/VueJs';
import Wordpress from './pages/technologies/Wordpress';



const Routing = () => {
  return (
    <HashRouter hashType='noslash'>
      <ScrollToTop />
      <Routes>
        <Route>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="angular" element={<Angularjs />} />
          <Route path="reactjs" element={<ReactJs />} />
          <Route path="python" element={<Python />} />
          <Route path="aspnet" element={<Aspnet />} />
          <Route path="vuejs" element={<VueJs />} />
          <Route path="php" element={<Php />} />
          <Route path="nodejs" element={<NodeJs />} />
          <Route path="laravel" element={<Laravel />} />
          <Route path="magento" element={<Magento />} />
          <Route path="wordpress" element={<Wordpress />} />
          <Route path="developmentcompany" element={<Offshore />} />
          <Route path="full-stack-development" element={<Development />} />
          <Route path="software-outsourcing-company" element={<SoftwareOutsourcing />} />
          <Route path="contact" element={<Contact />} />
          <Route path="free-quote" element={<FreeQuote />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog-details" element={<Blogdetails />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="about-us" element={<Aboutus />} />
          <Route path="team" element={<Team />} />
          <Route path="amazon-web-services" element={<Amazonweb />} />
          <Route path="it-consulting-services" element={<Itconsulting />} />
          <Route path="software-maintenance-support-services" element={<SoftwareMaintenance />} />
          <Route path="software-qa-and-testing-services" element={<SoftwareQaTestingServices />} />
          <Route path="ui-ux-design" element={<UIUXDesign />} />
          <Route path="database-management-services" element={<DatabaseManagementServices />} />
          <Route path="cyber-security" element={<CyberSecurity />} />
          <Route path="progressive-web-app-development" element={<Progressivewebapp />} />
          <Route path="erp-software-development-services" element={<ErpSoftwareDevelopment />} />
          <Route path="java-development" element={<JavaDevelopment />} />
          <Route path="hire-dedicated-software-developers-programmers" element={<HireDedicated />} />
          <Route path="vision" element={<Vision />} />
          <Route path="mission" element={<Mission />} />

        </Route>
      </Routes>
    </HashRouter>

  );

}


export default Routing;